import {
  GET_OVERVIEW,
  OVERVIEW_ERROR,
  OVERVIEW_LOADING
} from '../types';
import axios from 'axios';

export const getOverview = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const overview = await axios.get(`/hotel/today-overview/`);
    dispatch({
      type: GET_OVERVIEW,
      payload: overview.data,
    });
  } catch (err) {
    dispatch({
      type: OVERVIEW_ERROR,
      payload: err,
    });
  }
};

export const setLoading = () => {
  return {
    type: OVERVIEW_LOADING,
  };
};
