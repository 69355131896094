import {
  GET_TASKS,
  ADD_TASK,
  UPDATE_TASK,
  SET_CURRENT_TASK,
  CLEAR_CURRENT_TASK,
  TASKS_ERROR,
  SET_TASKS_LOADING,
  GET_ACTIVITY,
  GET_COMMENTS,
  POST_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  READ_COMMENT
} from '../types';
import { readComments } from '../actions/tasksActions';

const initialState = {
  tasks: null,
  current: null,
  loading: false,
  activity: null,
  comments: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload,
        loading: false,
      };
    case ADD_TASK:
      return {
        ...state,
        tasks: [...state.tasks, action.payload],
        loading: false,
      };
    case UPDATE_TASK:
      console.log()
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task.id === action.payload.id
            ? { ...task, complete: action.payload.task.complete }
            : task
        ),
        current: 
          state.current !== null ? { ...state.current, complete: action.payload.task.complete }
            : null
       
      };
    case SET_CURRENT_TASK:
      return {
        ...state,
        current: action.payload,
      };
    case CLEAR_CURRENT_TASK:
      return {
        ...state,
        current: null,
      };
    case SET_TASKS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TASKS_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    case GET_ACTIVITY:
      return {
        ...state,
        activity: action.payload
      };
    case GET_COMMENTS:
      return {
        ...state,
        comments: action.payload
      }
    case POST_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.payload]
      }
    case UPDATE_COMMENT:
      console.log(action.payload);
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment.id === action.payload.commentId
            ? comment = { ...comment, comment: action.payload.comment }
            : comment)
      }
    case READ_COMMENT:
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task.id === action.payload.id
            ? task = {...task, readComments: [...task.readComments, action.payload.userId]}
            : task)
      }
    case DELETE_COMMENT:
      console.log(action.payload);
      return {
        ...state,
        comments: state.comments.filter((comment) =>
          comment.id !== action.payload)
      }
    default:
      return state;
  }
};
