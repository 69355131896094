export const GET_HOTEL = 'GET_HOTEL';
export const HOTEL_ERROR = 'HOTEL_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const GET_TASKS = 'GET_TASKS';
export const TASKS_ERROR = 'TASKS_ERROR';

export const ADD_TASK = 'ADD_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const GET_ACTIVITY = 'GET_ACTIVITY';


export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const SET_CURRENT_ANNOUNCEMENT = 'SET_CURRENT_ANNOUNCEMENT';
export const CLEAR_CURRENT_ANNOUNCEMENT = 'CLEAR_CURRENT_ANNOUNCEMENT';
export const ANNOUNCEMENTS_ERROR = 'ANNOUNCEMENTS_ERROR';
export const SET_ANNOUNCEMENTS_LOADING = 'SET_ANNOUNCEMENTS_LOADING';

// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
// Data reducer types
export const SET_SCREAMS = 'SET_SCREAMS';
export const SET_SCREAM = 'SET_SCREAM';
export const LIKE_SCREAM = 'LIKE_SCREAM';
export const UNLIKE_SCREAM = 'UNLIKE_SCREAM';
export const DELETE_SCREAM = 'DELETE_SCREAM';
export const POST_SCREAM = 'POST_SCREAM';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';

export const SET_SLUG = 'SET_SLUG';
export const CLEAR_SLUG = 'CLEAR_SLUG';

export const SET_HOTEL = 'SET_HOTEL';
export const SET_HOTEL_LOADING = 'SET_HOTEL_LOADING';

export const GET_LOGIN_HOTEL = 'GET_LOGIN_HOTEL';
export const SET_HOTEL_LOGIN_ERRORS = 'SET_HOTEL_LOGIN_ERRORS';
export const CLEAR_HOTEL_LOGIN_ERRORS = 'CLEAR_HOTEL_LOGIN_ERRORS';
export const CLEAR_CURRENT_TASK = 'CLEAR_CURRENT_TASK';
export const SET_TASKS_LOADING = 'SET_TASKS_LOADING';
export const SET_CURRENT_TASK = 'SET_CURRENT_TASK';

export const GET_COMMENTS = 'GET_COMMENTS';
export const POST_COMMENT = 'POST_COMMENT';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const READ_COMMENT = 'READ_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';


export const GET_OVERVIEW = 'GET_OVERVIEW';
export const OVERVIEW_ERROR = 'OVERVIEW_ERROR';
export const OVERVIEW_LOADING = 'OVERVIEW_LOADING';
export const GET_RATE_COMPARISON = 'GET_RATE_COMPARISON';
export const GET_RATE_COMPARISON_TEST = 'GET_RATE_COMPARISON_TEST';
export const RATE_COMPARISON_ERROR = 'RATE_COMPARISON_ERROR';
export const RATE_COMPARISON_LOADING = 'RATE_COMPARISON_LOADING';
