import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginStyles from '../../views/Login.styles';
import LoginField from '../../components/LoginField';
import LoginButton from '../../components/LoginButton';
import { connect } from 'react-redux';
import { loginUser } from '../../store/actions/userActions';
import { Field, Label, Checkbox } from '@zendeskgarden/react-forms';

const Login = ({
  match,
  history,
  hotelLogin,
  loginUser,
  error,
  authenticated,
  hotelLoading,
  userLoading,
  hotelError,
  ...rest
}) => {
  const styles = LoginStyles();

  const [user, setUser] = useState({
    email: '',
    password: '',
    hotelSlug: hotelLogin.slug,
    hotelId: hotelLogin.id,
  });

  const { email, password } = user;

  const errorMessage = () => {
    switch (error) {
      case 'userNotFound':
        return 'A user with that username was not found!';
      case 'emailNotFound':
        return 'A user with that email was not found!';
      case 'emailEmpty':
        return 'Please enter a username or email address';
      case 'passwordIncorrect':
        return 'Password is not correct!';
      default:
        return error;
    }
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    // clearErrors();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === '' || password === '') {
      // setAlert('Please fill in all fields')
    } else {
      // login();
      loginUser(user, history);
    }
  };

  return (
    
    <div className={styles.wrapper}>
      <div className='left-pane'>
        <div className='brand'>
          <Link to='/'>
            <img src='/images/text-logo.svg' alt='STRĒMLĪND' />
          </Link>
        </div>
        <p>
          Connect, automate, organize, and simplify your daily hotel operations.
        </p>

        <img
          src='/images/login-illustration.png'
          alt='Illustration'
          className='illustration'
        />
      </div>
      <div className='right-pane'>
        <div className='login-header'>
          Don't have an account? <Link to='/register'>Sign Up</Link>
        </div>

        <div className={styles.form}>
          <div className='form-title'>Sign in to Strēmlīnd</div>
          <form onSubmit={onSubmit}>
            <LoginField
              className='u-mb-xl'
              value={email}
              name='email'
              placeholder='Enter phone number or email address'
              onChange={onChange}
              label='Phone or Email'
              error={
                error === 'emailNotFound' ||
                error === 'userNotFound' ||
                error === 'emailEmpty'
                  ? errorMessage()
                  : null
              }
            />
            <LoginField
              className='u-mb-xl'
              value={password}
              name='password'
              type='password'
              placeholder='Enter password'
              onChange={onChange}
              label='Password'
              error={error === 'passwordIncorrect' ? errorMessage() : null}
            />
            <LoginButton
              className='u-mb-xl'
              disabled={email === '' || password === '' ? true : false}
            >
              Log In
            </LoginButton>
            <div className='login-options'>
              <Field>
                {(1 == 2) && (
                  <Checkbox>
                    <Label>Remember me</Label>
                  </Checkbox>
                  
                )}
                <a href='#!' className='forgot-password'>
                  Forgot password?
                </a>
              </Field>
            </div>
          </form>

          {userLoading && (<div>Logging in...</div>)}
        </div>
      </div>
    </div>
    

  )
    
  
};

const mapStateToProps = (state) => ({
  hotelLogin: state.hotelLogin,
  hotelError: state.hotelLogin.error,
  authenticated: state.user.authenticated,
  hotelLoading: state.hotelLogin.loading,
  userLoading: state.user.loading
});
const mapActionsToProps = {
  loginUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Login);
