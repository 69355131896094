import { SET_HOTEL, HOTEL_ERROR, SET_HOTEL_LOADING } from '../types';
import axios from 'axios';

export const getHotel = () => async (dispatch) => {
  try {
    dispatch(setHotelLoading());
    const hotel = await axios.get(`/hotel/`);
    dispatch({
      type: SET_HOTEL,
      payload: hotel.data,
    });
  } catch (err) {
    dispatch({
      type: HOTEL_ERROR,
      payload: err,
    });
  }
};

// export const getHotelBySlug = (slug) => (dispatch) => {
//   axios
//     .get(`/hotelSlug/${slug}`)
//     .then((res) => {
//       dispatch({
//         type: SET_HOTEL,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: HOTEL_ERROR,
//         payload: err.response.data,
//       });
//     });
// };

export const setHotelLoading = () => {
  return {
    type: SET_HOTEL_LOADING,
  };
};
