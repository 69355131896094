import {
  GET_ANNOUNCEMENTS,
  ADD_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  SET_CURRENT_ANNOUNCEMENT,
  CLEAR_CURRENT_ANNOUNCEMENT,
  ANNOUNCEMENTS_ERROR,
  SET_ANNOUNCEMENTS_LOADING,
  GET_ACTIVITY,
  POST_COMMENT,
  LIKE_COMMENT,
  READ_COMMENT,
  GET_COMMENTS,
  UPDATE_COMMENT,
  DELETE_COMMENT
} from '../types';
import axios from 'axios';

export const getAnnouncements = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const announcements = await axios.get(`/hotel/announcements/`);
    dispatch({
      type: GET_ANNOUNCEMENTS,
      payload: announcements.data,
    });
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err,
    });
  }
};

export const addAnnouncement = (announcement) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await axios.post('/hotel/announcement/create', announcement);

    dispatch({
      type: ADD_ANNOUNCEMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err,
    });
  }
};

export const updateAnnouncement = (id, announcement) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/announcement/${id}/update`, announcement);

    dispatch({
      type: UPDATE_ANNOUNCEMENT,
      payload: {
        id: id,
        announcement: res.data
      },
    });
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err,
    });
  }
};

export const deleteAnnouncement = (announcementId, commentId) => async (dispatch) => {
  // try {
  //   const res = await axios.post(`/hotel/announcements/comment/delete`, {
  //     announcementId: announcementId,
  //     commentId: commentId
  //   });
  //   dispatch({
  //     type: DELETE_COMMENT,
  //     payload: commentId
  //   })
  // } catch (err) {
  //   dispatch({
  //     type: ANNOUNCEMENTS_ERROR,
  //     payload: err
  //   })
  // }
}

// Set current announcement
export const setCurrent = (announcement) => {
  return {
    type: SET_CURRENT_ANNOUNCEMENT,
    payload: announcement,
  };
};

// Clear current announcement
export const clearCurrent = () => {
  return {
    type: CLEAR_CURRENT_ANNOUNCEMENT,
  };
};

export const getAnnouncementActivity = (announcementId) => async (dispatch) => {
  try {
    const res = await axios.get(`/hotel/announcements/${announcementId}/activities`);

    dispatch({
      type: GET_ACTIVITY,
      payload: res
    })
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err
    })
  }
}


export const setLoading = () => {
  return {
    type: SET_ANNOUNCEMENTS_LOADING,
  };
};


export const postComment = (announcementId, comment) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/announcements/comment/create`, {
      announcementId: announcementId,
      comment: comment
    });
    dispatch({
      type: POST_COMMENT,
      payload:  res.data
    })
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err
    })
  }
}

export const updateComment = (announcementId, commentId, comment) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/announcements/comment/update`, {
      announcementId: announcementId,
      commentId: commentId,
      comment: comment
    });
    dispatch({
      type: UPDATE_COMMENT,
      payload: {
        commentId: commentId,
        comment: comment
      }
    })
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err
    })
  }
}

export const likeComment = (announcementId, commentId) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/announcements/comment/like`, {
      announcementId: announcementId,
      commentId: commentId
    });
    dispatch({
      type: LIKE_COMMENT,
      payload: res.data
    })
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err
    })
  }
}

export const deleteComment = (announcementId, commentId) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/announcements/comment/delete`, {
      announcementId: announcementId,
      commentId: commentId
    });
    dispatch({
      type: DELETE_COMMENT,
      payload: commentId
    })
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err
    })
  }
}

export const readComments = (announcementId, userId) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/announcements/comment/read`, {
      announcementId: announcementId,
    });
    dispatch({
      type: READ_COMMENT,
      payload: {
        id: announcementId,
        userId: userId
      }
    })
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err
    })
  }
}


export const getComments = (announcementId) => async (dispatch) => {
  try {
    const res = await axios.get(`/hotel/announcements/${announcementId}/comments`);
    dispatch({
      type: GET_COMMENTS,
      payload: res.data
    })
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENTS_ERROR,
      payload: err
    })
  }
}

// export const getComment = (announcementId, commentId) => async (dispatch) => {
//   try {
//     const res = await axios.get(`/hotel/announcements/${announcementId}/comment/${commentId}`);
//     dispatch({
//       type: GET_COMMENTS,
//       payload: res
//     })
//   } catch (err) {
//     dispatch({
//       type: ANNOUNCEMENTS_ERROR,
//       payload: err
//     })
//   }
// }
