import React, {useState} from 'react'
import makeStyles from './makeStyles';
import Svg from '../svg/icons';
const useStyles = makeStyles({
  root: {
       
      padding: '10px 16px',
      fontSize: 14,
      lineHeight: '16px',
      transition: 'all 0.2s linear',
      cursor: 'pointer',
      '& svg': {
        height: 16,
        float: 'right',
        '& path': {
          fill: '#252427',
          transition: 'all 0.2s linear',
        },
      },

      '&:hover': {
        backgroundColor: '#252427',
        color: 'white',
        
        '& svg path': {
          fill: 'white',
          transition: 'all 0.2s linear',
        },
      },
  }
});
const MenuItem = ({float, children, onClick, ...props}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const click = () => {
    setOpen(!open);
  }
  return (
    <li className={`${classes.root}`} onClick={onClick} props={props}>
      {children}
    </li>
  )
}

export default MenuItem
