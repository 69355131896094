import React, { useEffect, Fragment } from 'react';
import Drawer from '@material-ui/core/Drawer';
import MainViewStyles from './MainView.styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ProfileMenu from '../layout/ProfileMenu';
import Dashboard from '../pages/main/Dashboard.js';
import * as Svg from '../svg/icons';
import Schedule from '../pages/main/Schedule';
import Operations from '../pages/main/Operations';
import Maintenance from '../pages/main/Maintenance';
import Reports from '../pages/main/Reports';

import { connect } from 'react-redux';

import { Route, Redirect, NavLink } from 'react-router-dom';
import TopMenu from '../layout/TopMenu';

const ExactNavLink = (props) => <NavLink {...props} />;

const Main = ({ match, history, authenticated, hotel: { slug }, userLoading, hotelLoading, ...props }) => {
  useEffect(() => {
    if (authenticated) {
    } else {
      history.push(`/${match.params.hotelSlug}/login`);
      // console.log('not authed for main view, pushing to login');
    }
    //eslint-disable-next-line
  }, [authenticated]);

  useEffect(() => {
    // console.log('load user main view', match.params.hotelSlug, slug);
  }, [slug]);

  const classes = MainViewStyles();
  return (
    <div className={classes.root}>
      {userLoading && (
        <div style={{background: 'white', position: 'fixed', width: '100vw', height: '100vh'}}>
        Loading user...
        {/* <Loader /> */}

        </div>
      )}
       {hotelLoading && (
        <div style={{background: 'white', position: 'fixed', width: '100vw', height: '100vh'}}>
        Loading hotel details...
        {/* <Loader /> */}

        </div>
      )}
      {!userLoading && !hotelLoading && (
        <Fragment>
          <ProfileMenu />
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor='left'
      >
        <Divider className={classes.divider} />
        <List className={classes.menu}>
          <ExactNavLink to={`/${slug}/`} activeClassName={'active'}>
            <ListItem button>
              <ListItemIcon>
                <Svg.Dashboard></Svg.Dashboard>
              </ListItemIcon>
            </ListItem>
          </ExactNavLink>
          <ExactNavLink to={`/${slug}/schedule`} activeClassName={'active'}>
            <ListItem button>
              <ListItemIcon>
                <Svg.Schedule></Svg.Schedule>
              </ListItemIcon>
            </ListItem>
          </ExactNavLink>
          <ExactNavLink to={`/${slug}/operations`} activeClassName={'active'}>
            <ListItem button>
              <ListItemIcon>
                <Svg.Operations></Svg.Operations>
              </ListItemIcon>
            </ListItem>
          </ExactNavLink>
          <ExactNavLink to={`/${slug}/maintenance`} activeClassName={'active'}>
            <ListItem button>
              <ListItemIcon>
                <Svg.Maintenance></Svg.Maintenance>
              </ListItemIcon>
            </ListItem>
          </ExactNavLink>
          <ExactNavLink to={`/${slug}/reports`} activeClassName={'active'}>
            <ListItem button>
              <ListItemIcon>
                <Svg.Reports></Svg.Reports>
              </ListItemIcon>
            </ListItem>
          </ExactNavLink>
        </List>
        <div className={classes.stremlindBranding}>strēmlīnd</div>
      </Drawer>

      <main className={classes.content}>
        <TopMenu
          history={history}
          slug={match.params.hotelSlug}
          routerMatch={match}
        />

        <Route
          path={`/${match.params.hotelSlug}/`}
          component={Dashboard}
          routerMatch={match}
        />

        <Route
          path={`/${match.params.hotelSlug}/schedule`}
          component={Schedule}
          routerMatch={match}
        />

        <Route
          path={`/${match.params.hotelSlug}/operations`}
          component={Operations}
          routerMatch={match}
        />

        <Route
          path={`/${match.params.hotelSlug}/maintenance`}
          component={Maintenance}
          routerMatch={match}
        />

        <Route
          path={`/${match.params.hotelSlug}/reports`}
          component={Reports}
          routerMatch={match}
        />
      </main>
        </Fragment>
      )}

      
    </div>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.hotel,
  authenticated: state.user.authenticated,
  userLoading: state.user.loading,
  hotelLoading: state.hotel.loading
});
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Main);
