import React from 'react'
import makeStyles from './makeStyles';
const useStyles = makeStyles({
  root: {
    color: '#FFFFFF',
    height: 40,
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 0,
    textTransform: 'none',
    backgroundColor: '#434343',
    padding: '0px 25px',
    lineHeight: '40px',
    verticalAlign: 'middle',
    cursor: 'pointer',
    boxShadow: '0 5px 15px -5px rgba(27,26,49,0.3)',
    display: 'inline-block'
  }
});

const Button = ({label, inline, fullWidth, action, ...props}) => {
  const classes = useStyles();


  return (
    <div className={classes.root} onClick={action}>
      {props.children}
    </div>
  )
}

export default Button
