import {
  GET_RATE_COMPARISON,
  RATE_COMPARISON_ERROR,
  RATE_COMPARISON_LOADING,
  GET_RATE_COMPARISON_TEST
} from '../types';
import axios from 'axios';

export const getRateCompare = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const rateCompare = await axios.get(`/hotel/nearby-hotels/`);
    dispatch({
      type: GET_RATE_COMPARISON,
      payload: rateCompare.data,
    });
  } catch (err) {
    dispatch({
      type: RATE_COMPARISON_ERROR,
      payload: err,
    });
  }
};

export const getRateCompareTest = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const rateCompare = await axios.get(`/hotel/rate-comparison/`);
    console.log(rateCompare)
    dispatch({
      type: GET_RATE_COMPARISON_TEST,
      payload: rateCompare.data,
    });
  } catch (err) {
    dispatch({
      type: RATE_COMPARISON_ERROR,
      payload: err,
    });
  }
};

export const setLoading = () => {
  return {
    type: RATE_COMPARISON_LOADING,
  };
};
