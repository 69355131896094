import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { useEffect } from 'react';

const useStyles = makeStyles({
  menu: {
    position: 'relative',
    zIndex: 0,
    overflowY: 'visible !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-30px',
      left: '-40px',
      width: 'calc(100% + 80px)',
      height: '254px',
      // backgroundImage: `url('images/menu-bg.png')`,
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: '100% auto',
      // backgroundPosition: 'bottom left',
      zIndex: 0,
    },
    '& .MuiTabs-fixed, .MuiTabs-root': {
      overflow: 'visible !important',
    },

    '& .MuiTabs-flexContainer': {
      display: 'inline-block',
      background: '#fff',
      boxShadow: '0 15px 30px -10px rgba(172,180,184,0.2)',

      '& .MuiTab-root': {
        zIndex: 2,
        textTransform: 'none',
        color: '#797B88',
        '&.Mui-selected': {
          color: '#434343',
        },

        '& .MuiTab-wrapper': {
          width: 'initial',
          '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            bottom: '-2px',
            borderBottom: '2px solid #252427',
          },
        },
      },
    },
    '& .MuiTabs-indicator': {
      bottom: 0,
      height: 4,
      background: '#252427',
      borderRadius: 0,
      zIndex: 1,
      transform: 'scaleX(0.35)',
    },
  },
});

const TopMenu = ({ routerMatch, history, slug }) => {
  const classes = useStyles();
  const location = useLocation().pathname;
  const [value, setValue] = useState(location);
  const handleChange = (event, newValue) => {
    if (newValue !== location) {
      setValue(newValue);
      history.push(newValue);
    }
  };

  useEffect(() => {
    if (location !== value && location !== undefined) {
      if (location !== slug) {
        setValue(location);
      }
    }
  }, [location]);

  return (
    <div className={classes.menu}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        aria-label='full width tabs example'
      >
        <Tab label='Overview' value={`/${slug}/`} default />
        <Tab label='Announcements' value={`/${slug}/announcements`} />
        <Tab label='Tasks & Notes' value={`/${slug}/tasks-and-notes`} />
        <Tab label='Daily Checklist' value={`/${slug}/checklist`} />
        <Tab label='Requests' value={`/${slug}/requests`} />
      </Tabs>
    </div>
  );
};

export default TopMenu;
