import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
  palette: {
    background: {
      default: '#FBFBFC',
    },
    text: {
      primary: '#434343',
    },
  },
});

export default Theme;
