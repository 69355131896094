import {
  GET_ANNOUNCEMENTS,
  ADD_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  SET_CURRENT_ANNOUNCEMENT,
  CLEAR_CURRENT_ANNOUNCEMENT,
  ANNOUNCEMENTS_ERROR,
  SET_ANNOUNCEMENTS_LOADING,
  GET_ACTIVITY,
  GET_COMMENTS,
  POST_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  READ_COMMENT
} from '../types';
import { readComments } from '../actions/announcementsActions';

const initialState = {
  announcements: null,
  current: null,
  loading: false,
  activity: null,
  comments: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload,
        loading: false,
      };
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        announcements: [...state.announcements, action.payload],
        loading: false,
      };
    case UPDATE_ANNOUNCEMENT:
      console.log()
      return {
        ...state,
        announcements: state.announcements.map((announcement) =>
          announcement.id === action.payload.id
            ? { ...announcement, complete: action.payload.announcement.complete }
            : announcement
        ),
        current: 
          state.current !== null ? { ...state.current, complete: action.payload.announcement.complete }
            : null
       
      };
    case SET_CURRENT_ANNOUNCEMENT:
      return {
        ...state,
        current: action.payload,
      };
    case CLEAR_CURRENT_ANNOUNCEMENT:
      return {
        ...state,
        current: null,
      };
    case SET_ANNOUNCEMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ANNOUNCEMENTS_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    case GET_ACTIVITY:
      return {
        ...state,
        activity: action.payload
      };
    case GET_COMMENTS:
      return {
        ...state,
        comments: action.payload
      }
    case POST_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.payload]
      }
    case UPDATE_COMMENT:
      console.log(action.payload);
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment.id === action.payload.commentId
            ? comment = { ...comment, comment: action.payload.comment }
            : comment)
      }
    case READ_COMMENT:
      return {
        ...state,
        announcements: state.announcements.map((announcement) =>
          announcement.id === action.payload.id
            ? announcement = {...announcement, readComments: [...announcement.readComments, action.payload.userId]}
            : announcement)
      }
    case DELETE_COMMENT:
      console.log(action.payload);
      return {
        ...state,
        comments: state.comments.filter((comment) =>
          comment.id !== action.payload)
      }
    default:
      return state;
  }
};
