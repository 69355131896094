import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as moment from 'moment';
import {capFirstLetter} from '../../../utils/helpers';
import * as Svg from '../../../svg/icons';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  table: {

    tableLayout: 'fixed',
    whiteSpace: 'nowrap',
    // '& td, th': {
    //   height: '40px',
    //   padding: '4px 8px'
    // },
    '& .button-cell': {
      padding: '0 !important',
      position: 'relative',
      '& button': {
        width: '100%'
      }
    },
    '& .done-button': {
      borderRadius: '0px',

      textTransform: 'none',
      minWidth: '92px',
      boxShadow: 'none',
      height: '40px',
      backgroundColor: '#434343',
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0,
      textAlign: 'center',
      '-webkit-appearance': 'none',
      outline: 'none',
      border: 'none',
      color: 'white',
      cursor: 'pointer',

      '&.complete': {
        backgroundColor: '#BDBDBD',
        color: '#9B9B9B',
      },
    },
    '& .actions': {
      '& a': {
        margin: '0px 6px',
        display: 'inline-block',
      },
    },
    '& .done': {
      '& td': {
        color: '#B8B4CC',
      },

      '& td:nth-child(2)': {
        
      },
    },
    '& .priority-indicator': {
      width: '8px',
      minWidth: '8px',
      maxWidth: '8px',
      position: 'relative',
      left: -32,
      padding: '0',
      
        borderBottom: 'none',
      // display: 'block',

      '&.important': {
        background: '#d2f1e3',
      },
      '&.urgent': {
        background: '#fbdcd6',
      },
      '&:after': {
        display: 'none'
      }
      
    },

    '& .unread': {
      display: 'inline-block',
      position: 'relative',
      '&:after': {
        position: 'absolute',
        top: -2,
        right: 2,
        width: 8,
        height: 8,
        background: '#E94F30',
        content: '""',
        display: 'block',
        borderRadius: '50%'
      }
    },
    '& tbody tr': {
      cursor: 'pointer',
      transition: 'background-color 0.2s ease-out',

      '&.important': {},
      '&:hover, &.open': {
        backgroundColor: ' #f5f5f5',
      },

      '& .title-cell': {
        fontSize: 14
      },

      '&.complete': {
        '& td:not(.title-cell)': {
          color: '#bdbdbd',
        },
        '& .title-cell': {
          color: '#bdbdbd',
          textDecoration: 'line-through',
        }
      },

      '& .icons svg': {
        margin: '0px 6px'
      },

      '& td': {
        fontSize: 12,


      },

      // '&.important': {
      //   background: 'rgba(210,241,227,0.50);',
      // },
      // '&.urgent': {
      //   background: 'rgba(251, 220, 214, 0.50)',
      // },
    },
    '& thead tr': {
      '& th:nth-child(1)': {
        width: 8,
        padding: 0,
        borderBottom: 'none'
      },
      '& th:nth-child(2)': {
        width: '25%'
      },
      '& th': {
        width: 'calc((100% - 25% - 8px - 92px - 100px) / 7)'
      },
      '& th.date-col': {
        width: 100
      },
      '& th:last-child': {
        width: 92
      }
    },
    '&.add-new': {
      '& th': {
        height: 40
      },
      '& th:nth-child(1)': {
        width: 8,
        padding: 0
      },
      '& th:nth-child(2)': {
        width: 'calc(100% - 8px)'
      },
    }
  },
});
const TasksTable = ({
  addNew,
  tasks,
  members,
  departments,
  openTask,
  setComplete,
  currentTaskId,
  userId
}) => {
  const classes = useStyles();

  // const get

  const memberNameFromId = (id) => {
    const matchedMember = members.find((member) => {
      return member.id === id;
    });
    if (!matchedMember) {
      return;
    }
    return matchedMember.firstName;
  };

  const departmentNameFromId = (id) => {
    const matchedDepartment = departments.find((department) => {
      return department.id === id;
    });
    if (!matchedDepartment) {
      return;
    }
    return matchedDepartment.name;
  };

  const firstNamesList = (assignedMembers) => {
    
    if ((assignedMembers !== undefined) && (assignedMembers !== []) && (assignedMembers !== ['all'])) {
      if (assignedMembers.length === 0 || assignedMembers === ['all']) {
      return 'All';
      }
       const res = [];
        const matchingMembers = members.filter((member) => {
          return assignedMembers.includes(member.id);
        });
        matchingMembers.forEach((member) => {
          res.push(member.firstName);
        });
        return res.join(', ');
    } else {
      return 'All';
    }
   
  };

  const getDate = (dueDate) => {
    return typeof dueDate !=='object' ? moment(new Date(dueDate)).format('MMM D') : moment(new Date(dueDate._seconds * 1000)).format('MMM D')
  }

  const clickTask = (e) => {
    console.log(e)
    openTask(e)
  }

  return (
    <TableContainer>
      <Table className={`${classes.table} ${addNew ? 'add-new': ''}`}>
        <TableHead>
          {!addNew && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Assigned To</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
          {addNew && (
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableHead>

        {!addNew && (
          <TableBody>
            {tasks.map((task) => (
              <TableRow
                key={task.id}
                className={`${task.complete ? 'complete' : ''}  ${
                    task.priority && task.priority
                  } ${task.id === currentTaskId ? 'open' : ''}`}
                onClick={() => openTask(task.id)}
              >
                <TableCell className={`priority-indicator ${task.priority}`}></TableCell>
                <TableCell scope='row' className='title-cell'>
                  {task.title}
                </TableCell>
                <TableCell>{capFirstLetter(task.category)}</TableCell>
                <TableCell>{departmentNameFromId(task.department)}</TableCell>
                <TableCell>{task.location}</TableCell>
                <TableCell>{firstNamesList(task.assignedTo)}</TableCell>
                <TableCell>{memberNameFromId(task.createdBy)}</TableCell>
                <TableCell>
                  {getDate(task.dueDate)}
                </TableCell>
                <TableCell className='icons'>
                  {task.isRecurringTask ? <Svg.Repeat></Svg.Repeat> : ''}
                  {task.hasComment ? <span className={task.readComments && task.readComments.find((i) => i === userId) ? '' : 'unread'}><Svg.Comment></Svg.Comment></span> : ''}
                  {task.private ? <Svg.Lock></Svg.Lock> : ''}
                </TableCell>
                <TableCell className='button-cell'>
                  <button
                    className={`done-button ${task.complete ? 'complete' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setComplete(task.id, !task.complete);
                    }}
                  >
                    {task.complete ? 'Open' : 'Close'}
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        {addNew && (
          <TableBody>
            {tasks.map((task) => (
              <TableRow
                key={task.id}
                className={`${task.complete && task.complete} ${
                  task.priority && task.priority
                } ${task.id === currentTaskId ? 'open' : ''}`}
                onClick={() => clickTask(task.id)}
              >
                <TableCell className={`priority-indicator ${task.priority}`}></TableCell>
                <TableCell scope='row' className='title-cell'>{task.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({
  currentTaskId: state.tasks.current === null ? '' : state.tasks.current.id,
  userId: state.user.credentials.userId
});

export default connect(mapStateToProps, {
  
})(TasksTable);
