import React from 'react'
import * as moment from 'moment';
import makeStyles from '../../theme/makeStyles';
import GetUser from '../../utils/getUser';
import {capFirstLetter} from '../../utils/helpers';

const useStyles = makeStyles({
  root: {

  },
  item: {


    '& .date': {
      float: 'right',
      color: '#9B9B9B'
    }
  }
});



const Activity = ({ activityArray, category }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{marginBottom: 25}}><b>Activities</b></div>
      
      
      {activityArray && activityArray.map((act, index) => (
        <div className={classes.item} key={index}>
          
          {act.activity === 'create' && (
            <span>
              {capFirstLetter(category)} was created by{' '}
            <GetUser id={act.userId} />
            </span>
          )}

          {act.activity === 'complete' && act.value === true && (
            <span>
              {capFirstLetter(category)} was marked complete by{' '}
            <GetUser id={act.userId} />
            </span>
          )}

          {act.activity === 'complete' && act.value === false && (
            <span>
              {capFirstLetter(category)} was reopened by{' '}
            <GetUser id={act.userId} />
            </span>
          )}
          
          
        
          <span className="date"> {`${moment(new Date(act.createdAt._seconds * 1000)).fromNow()}`}</span>
        </div>

      ))}
    </div>
  )
}

export default Activity
