import React from 'react';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';

import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#252427 !important',
    boxShadow: '0 5px 15px -5px rgba(27,26,49,0.3) !important',
    borderRadius: '0 !important',
    fontSize: '16 !important',
    fontWeight: '500 !important',

    '&[disabled]': {
      backgroundColor: '#BDBDBD !important',
      color: '#999999 !important',
    },
  },
}));

const LoginButton = ({ className, children, value, ...rest }) => {
  const styles = Styles();
  const classNames = `${className} ${styles.root} `;

  return (
    <ZendeskButton
      isPrimary={true}
      isStretched={true}
      className={classNames}
      type='submit'
      {...rest}
    >
      {children}
    </ZendeskButton>
  );
};

export default LoginButton;
