import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LoginStyles from '../../views/Login.styles';
import LoginButton from '../../components/LoginButton';
import { connect } from 'react-redux';
import LoginField from '../../components/LoginField';
import PropTypes from 'prop-types';
import { signupUser } from '../../store/actions/userActions';
import { Field, Label, Checkbox, Message } from '@zendeskgarden/react-forms';
import PasswordCreate from '../../components/PasswordCreate';

const Register = ({
  history,
  signupUser,
  error,
  user: { authenticated },
  hotelSlug,
}) => {
  const styles = LoginStyles();
  const [submitted, setSubmitted] = useState(false);

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    agree: false,
  });
  // const error = '';
  const errorMessage = (field) => {
    if (error === field) {
      switch (error) {
        case 'firstName':
          return 'First name is a mandatory field';
        case 'lastName':
          return 'Last name is a mandatory field';
        case 'invalidEmail':
          return 'Invalid email address';
        case 'userExists':
          return 'A user with that email address already exists!';
        case 'passwordShort':
          return 'Please enter a password longer than 8 characters.';
        case 'agreeBox':
          return 'You must agree to our terms of service to continue';
        case 'passwordMismatch':
          return 'Passwords do not match';
        default:
          if (error) {
            return 'Something went wrong...';
          } else {
            return null;
          }
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    console.log(authenticated);

    if (authenticated) {
      console.log(hotelSlug);
      history.push(`/register/success/`);
    }
    // eslint-disable-next-line
  }, [authenticated]);

  const { firstName, lastName, email, phone, password, agree } = user;

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    // clearErrors();
  };

  const onCheckbox = (e) => {
    setUser({ ...user, agree: !agree });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      password === '' ||
      phone === ''
    ) {
      // setErrors('formEmpty');
    } else {
      signupUser(user, history);
    }

    setSubmitted(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className='left-pane'>
        <div className='brand'>
          <Link to='/'>
            <img src='/images/text-logo.svg' alt='STRĒMLĪND' />
          </Link>
        </div>
        <p>
          Connect, automate, organize, and simplify your daily hotel operations.
        </p>

        <img
          src='/images/registration-illustration.png'
          alt='Illustration'
          className='illustration'
        />
      </div>
      <div className='right-pane'>
        <div className='login-header'>
          Already have an account? <Link to='/login'>Sign In</Link>
        </div>

        <div className={styles.form}>
          <div className='form-title'>Sign up to Strēmlīnd</div>

          {error && <div className='errors'>{errorMessage()}</div>}

          <form onSubmit={onSubmit}>
            <LoginField
              className='u-mb-xl'
              value={firstName}
              type='text'
              name='firstName'
              placeholder='Enter first name'
              onChange={onChange}
              label='First Name'
              error={errorMessage('firstName')}
            />
            <LoginField
              className='u-mb-xl'
              value={lastName}
              type='text'
              name='lastName'
              placeholder='Enter last name'
              onChange={onChange}
              label='Last Name'
              error={errorMessage('lastName')}
            />

            <LoginField
              className='u-mb-xl'
              value={email}
              name='email'
              placeholder='Enter email address'
              onChange={onChange}
              label='Email'
              error={errorMessage('invalidEmail') || errorMessage('userExists')}
            />

            <LoginField
              className='u-mb-xl'
              value={phone}
              name='phone'
              placeholder='Enter phone number'
              onChange={onChange}
              label='Phone'
              error={errorMessage('invalidPhone')}
            />

            <PasswordCreate
              className='u-mb-xl'
              value={password}
              name='password'
              type='password'
              placeholder='Min 8 characters, 1 upper case, 1 symbol, 1 number'
              label='Password'
              onChange={onChange}
              error={errorMessage('passwordShort')}
            />
            <div className='agreement-box'>
              <Field>
                <Checkbox name='agree' value={agree} onChange={onCheckbox}>
                  <Label>
                    Creating an account means you are agreeing with our{' '}
                    <a>Terms of service</a>, <a>Policy</a> and default{' '}
                    <a>notification settings</a>.
                  </Label>
                </Checkbox>
                {errorMessage('agreeBox') && (
                  <Message className='message'>
                    {errorMessage('agreeBox')}
                  </Message>
                )}
              </Field>
            </div>
            <LoginButton
              disabled={
                firstName === '' ||
                lastName === '' ||
                email === '' ||
                password === '' ||
                !agree
              }
              className='u-mb-xl'
            >
              Create Account
            </LoginButton>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  error: state.user.error,
  signupUser: PropTypes.func.isRequired,
  hotelSlug: state.hotel.slug,
});
const mapActionsToProps = {
  signupUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Register);
