import {
  GET_LOGIN_HOTEL,
  SET_HOTEL_LOGIN_ERRORS,
  CLEAR_HOTEL_LOGIN_ERRORS,
} from '../types';

const initialState = {
  name: null,
  slug: null,
  id: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN_HOTEL:
      return {
        ...state,
        name: action.payload.name,
        slug: action.payload.slug,
        id: action.payload.id,
      };
    case SET_HOTEL_LOGIN_ERRORS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_HOTEL_LOGIN_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
