import {
  GET_OVERVIEW,
  OVERVIEW_ERROR,
  OVERVIEW_LOADING
} from '../types';

const initialState = {
  overview: {},
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OVERVIEW:
      return {
        ...state,
        overview: action.payload,
        loading: false,
      };
    case OVERVIEW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case OVERVIEW_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
