import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  SET_HOTEL,
} from '../types';
import axios from 'axios';

import { getHotel } from './hotelActions';
// import { getHotel } from './hotelActions';

export const loginUser = (userData, history) => async (dispatch) => {
  // dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(`/login/`, userData);
    setAuthorizationHeader(res.data.token);
    await dispatch(getUserData());
    dispatch({ type: CLEAR_ERRORS });
    // history.push('/app/schedule');
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data.msg,
    });
  }
};

export const signupUser = (newUserData, history) => (dispatch) => {
  // dispatch({ type: LOADING_UI });
  axios
    .post('/signup', newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData(history));
      dispatch({ type: CLEAR_ERRORS });
      // history.push('/app');
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data.msg,
      });
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('FBIdToken');
  delete axios.defaults.headers.common['Authorization'];
  dispatch({ type: SET_UNAUTHENTICATED });
};

export const getUserData = (history) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const user = await axios.get('/user');

    console.log(user);
    // await dispatch(getHotel(user.data.hotels[0].id));

    // const hotel = await axios.get(`/hotel/`);

    dispatch(getHotel());

    dispatch({
      type: SET_USER,
      payload: user.data,
    });
    // dispatch({
    //   type: SET_HOTEL,
    //   payload: hotel.data,
    // });

    // history.push(`/${hotel.data.slug}`);
  } catch (err) {
    dispatch({ type: SET_UNAUTHENTICATED });
    console.log(err);
  }
};

// export const uploadImage = (formData) => (dispatch) => {
//   dispatch({ type: LOADING_USER });
//   axios
//     .post('/user/image', formData)
//     .then(() => {
//       dispatch(getUserData());
//     })
//     .catch((err) => console.log(err));
// };

export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post('/user', userDetails)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

// export const markNotificationsRead = (notificationIds) => (dispatch) => {
//   axios
//     .post('/notifications', notificationIds)
//     .then((res) => {
//       dispatch({
//         type: MARK_NOTIFICATIONS_READ,
//       });
//     })
//     .catch((err) => console.log(err));
// };

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common['Authorization'] = FBIdToken;
};
