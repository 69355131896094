import React, {useState, useEffect} from 'react'

const TextInput = ({ value, placeholder, name, onChange, disabled }) => {
  
  const [state, setState] = useState({ value: '' });

  useEffect(() => {
    if (value !== state) {
      setState({ value: value });
    }
  }, [value])

  const updateValue = (e) => {
    onChange(e);
    setState({value: e.target.value});
  }

  return (
    <div>
      <input value={state.value} placeholder={placeholder} name={name} onChange={updateValue} disabled={disabled} type="text"/>
    </div>
  )
}

export default TextInput;
