import React, {useEffect, useState} from 'react'
import * as moment from 'moment';
import makeStyles from '../../theme/makeStyles';
import GetUser from '../../utils/getUser';
import { connect } from 'react-redux';
import { likeComment, deleteComment, updateComment } from '../../store/actions/tasksActions';
import Button from '../../theme/Button';
import DotMenu from '../../theme/DotMenu';
import MenuItem from '../../theme/MenuItem';
import * as Svg from '../../svg/icons';
const useStyles = makeStyles({
  root: {
    
    '& .like': {
      cursor: 'pointer',
      marginRight: 12
    }
  },
  item: {
    marginBottom: 32,
    '& .date': {

    },
    '& .comment': {
      marginLeft: 46,
    },
    '& .editing': {
      '& input': {
        border: '1px solid rgba(172, 180, 184, 0.2)',
        padding: 12,
        marginBottom: 12
      }
    }
  },
  aside: {
    float: 'right'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 8,
    '& .user-image': {
      width: 30,
      height: 30,
      borderRadius: 30,
      background: 'gray',
      marginRight: 16
    },
    '& span': {
      marginRight: 32
    },
    '& .date': {
      fontSize: 12,
      color: '#9B9B9B'
    }
  }
});


const Comments = ({ comments, currentTask, likeComment, deleteComment, updateComment, currentUserId }) => {
  const classes = useStyles();

  const [editComment, setEditComment] = useState({
    comment: '',
    editing: false,
    id: null
  });

  const like = (commentId) => {
    likeComment(currentTask.id, commentId);
  }

  const del = (commentId) => {
    deleteComment(currentTask.id, commentId);
  }

  const edit = (commentId) => {
    if (editComment.id !== commentId) {
      setEditComment({
        ...editComment,
        comment: comments.find((c) => c.id = commentId).comment,
        id: commentId
      });
    } else {
      setEditComment({
        ...editComment,
        comment: comments.find((c) => c.id = commentId).comment,
        id: commentId
      });
    }
   
  }

  const updateEdit = (value) => {
    setEditComment({
      ...editComment,
      comment: value
    });
  }

  const executeUpdate = () => {
    updateComment(currentTask.id, editComment.id, editComment.comment);
    setEditComment({
      comment: '',
      id: null,
    })
  }


  return (
    <div className={classes.root}>
      {comments && comments.map((comment, index) => (
        <div className={classes.item} key={comment.id}>
          <div className={classes.header}>
            <div className="user-image">
              
            </div>
            <div>
              <span className="name"><b><GetUser id={comment.userId} lastInitial /></b></span>
              <span className="date">{moment(comment.createdAt._seconds * 1000).format('LLL')}</span>
            </div>
          </div>
          
          <div className="comment">
            {editComment.id !== comment.id && (
              <div className="viewing">
                {` ${comment.comment}`}

                <div className={classes.aside}>
                  <span className="like" onClick={() => like(comment.id)}>
                    <Svg.Like color={comment.likes.includes(currentUserId) ? '#3B3B3B' : ''}/>
                  </span>
                  
                  <DotMenu inline openLeft>
                    <MenuItem onClick={() => edit(comment.id)}>
                      Edit Comment
                    </MenuItem>
                    <MenuItem onClick={() => del(comment.id)}>
                      Delete Comment
                    </MenuItem>
                </DotMenu>
                </div>
                
              </div>
            )}
           
            {editComment.id === comment.id && (
              <div className="editing">
                <input type="text" value={editComment.comment} onChange={(e) => updateEdit(e.target.value)} />

                <Button action={() => { executeUpdate() }}>Update</Button>
              </div>
            )}
           
          </div>
        </div>

      ))}
    </div>
  )
}


const mapStateToProps = (state) => ({
  currentTask: state.tasks.current,
  comments: state.tasks.comments,
  currentUserId: state.user.credentials.userId
});

export default connect(mapStateToProps, {
  likeComment,
  deleteComment,
  updateComment
})(Comments);
