import React, {useState} from 'react'
import makeStyles from './makeStyles';
import * as Svg from '../svg/icons';
const useStyles = makeStyles({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,

    '&.float': {
      float: 'right',
      position: 'relative'
    },
    '&.inline': {
      float: 'none',
      position: 'relative',
      display: 'inline-block'
    },

    '& .trigger': {
      cursor: 'pointer',
      padding: '0px 6px',
    },
    
  },
    dropdown: {
    position: 'absolute',
    minWidth: 175,
    left: 0,
    top: '100%',
    transition: 'opacity 0.1s linear, transform 0.3s ease-out',
    opacity: 0,
    transform: 'translateY(-10px)',
    pointerEvents: 'none',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 15px 30px -10px rgba(158,158,158,0.3)',
    zIndex: 20,
    '&.open': {
      opacity: 1,
      transform: 'translateY(0px)',
      pointerEvents: 'all',
      },
    
      '&.left': {
        left: 'initial',
        right: 0,
      },

    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },

  },
});
const DotMenu = ({float, inline, openLeft, ...props}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const click = () => {
    setOpen(!open);
  }
  return (
    <div className={`${classes.root} ${float ? 'float' : ''} ${inline ? 'inline': ''}`}>
      <span className="trigger" onClick={() => click()}>
        <Svg.More />
      </span>


      <div className={`${classes.dropdown} ${open ? 'open' : ''} ${openLeft ? 'left': ''}`}>
        <ul>
          {props.children}
        </ul>
      </div>
    </div>
  )
}

export default DotMenu
