import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
});

function createData(name, department, replacement) {
  return { name, department, replacement };
}

const rows = [
  createData('Augusta Moody', 'Front Desk', 'Caleb Alexander'),
  createData('Alta Walsh', 'Breakfast', 'Beatrice Hunter'),
  createData('Joshua Wilkins', 'Maintenance', 'Jack McKinney'),
  createData('Jesse Ruiz', 'Restaurant', '-'),
];

function Schedule() {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Department</TableCell>
            <TableCell>Replacement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">{row.name}</TableCell>
              <TableCell>{row.department}</TableCell>
              <TableCell>{row.replacement}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Schedule;
