import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
// import AuthContext from '.././context/auth/authContext';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../store/actions/userActions';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    right: 40,
    top: 32,
    zIndex: 40,
  },
  greeting: {
    color: '#434343',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    border: '1px solid #FFFFFF',
    width: 43,
    height: 43,
  },
}));

const ProfileMenu = ({ user, loading, logoutUser }) => {
  // const authContext = useContext(AuthContext);
  // const { logout, user } = authContext;
  const onLogout = (e) => {
    e.preventDefault();
    logoutUser();
  };

  useEffect(() => {
    // console.log(user);
  });

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {!loading && user && (
        <Fragment>
          <div className={classes.greeting}>
            Hello, {!loading && user.credentials.firstName}
            <br />
            <a href='#/!' style={{ fontSize: 8 }} onClick={onLogout}>
              (Logout)
            </a>
            {/* <Link style={{ fontSize: 8 }} to='/logout'>
          (Logout route)
        </Link> */}
          </div>
          <Badge
            badgeContent={'9+'}
            color='secondary'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Avatar
              className={classes.image}
              alt={!loading && user.firstName}
              // src='/images/auvik.jpg'
            />
          </Badge>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.loading,
});
const mapActionsToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapActionsToProps)(ProfileMenu);
