import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

const GetUser = ({ id, firstName, fullName, lastInitial, members }) => {
  useEffect(() => {
    if (id) {
      const match = members.find((member) => {
        return id === member.id;
      });
      console.log(match);
      setUser(match);
    }
  }, [])

  const [user, setUser] = useState(null);

  return (
    <span>{user !== null && (

      firstName ? user.firstName :
        lastInitial ? `${user.firstName} ${user.lastName.charAt(0)}.` :
          user.firstName


    )
    }</span>
  )
}




const mapStateToProps = (state) => ({
  members: state.hotel.members
});

export default connect(mapStateToProps, {
 
})(GetUser);
