import React from 'react';
// import AuthContext from '../context/auth/authContext';
import { Route } from 'react-router-dom';
import Register from '../pages/registration/Register';
import RegistrationSuccess from '../pages/registration/RegistrationSuccess';
import LoginStyles from './Login.styles';

const Registration = (props) => {
  const styles = LoginStyles();

  // useEffect(() => {
  //   // loadUser();
  //   // if (isAuthenticated) {
  //   //   props.history.push('/app');
  //   // }
  // }, [isAuthenticated, props.history]);

  return (
    <div className={styles.root}>
      <Route exact path='/register/' component={Register} />
      <Route path='/register/success' component={RegistrationSuccess} />
    </div>
  );
};

export default Registration;
