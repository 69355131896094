import React from 'react';
import Main from './views/MainView.js';
import LoginView from './views/LoginView.js';
import Logout from './pages/login/Logout';
import Registration from './views/RegistrationView.js';
import Public from './views/PublicView';
import NotFound from './pages/NotFound';
import SuperAdmin from './views/SuperAdminView';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import dotenv from 'dotenv';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import '@zendeskgarden/css-utilities';
import './index.scss';
import Theme from './theme/Theme';
import store from './store/store';
import jwtDecode from 'jwt-decode';
import { Provider } from 'react-redux';
import { SET_AUTHENTICATED } from './store/types';
import { logoutUser, getUserData } from './store/actions/userActions';
import axios from 'axios';

dotenv.config();

axios.defaults.baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/stremlind-app/us-central1/api'
    : 'https://us-central1-stremlind-app.cloudfunctions.net/api';

const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}

const App = (props) => {
  return (
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={Theme}>
        <Router>
          <Switch>
            <Route exact path='/:hotelSlug/login' component={LoginView} />

            <Route exact path='/login' component={LoginView} />

            <Route path='/logout' component={Logout} />

            <Route path='/register' component={Registration} />

            <Route path='/:hotelSlug/' component={Main} />

            <Route path='/sadmin' component={SuperAdmin} />

            <Route exact path='/' component={Public} />

            <Route path='*' component={NotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
