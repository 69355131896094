import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, SvgIcon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '../../theme/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import * as moment from 'moment';
import TasksTable from '../../layout/tables/Tasks/TasksTable';

import {
  getTasks,
  setCurrent,
  clearCurrent,
  updateTask,
} from '../../store/actions/tasksActions';

import * as Svg from '../../svg/icons';
import TaskEditor from '../../layout/editors/TaskEditor';

import FilterMenu from '../../layout/tables/FilterMenu';
import Switch from '../../theme/Switch';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%',


      '&.MuiPaper-root': {
        padding: '20px 32px !important'
      }
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 24px 150px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '32px',
        '& .close': {
          color: '#434343',
          fontWeight: 500,
          cursor: 'pointer',
        },
         '& .delete': {
          color: '#DE6552',
          fontWeight: 500,
          cursor: 'pointer',
        },
      },

    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px',
      },
      '& .add-pane': {
        display: 'block',
      },
    },
    '& .MuiExpansionPanelSummary-root.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: 0,
      transform: 'translateY(20px)',
    },

    '& .MuiExpansionPanel-root': {
      padding: '0 !important',
      margin: '0 !important',
      boxShadow: 'none !important',
      '&:before': {
        display: 'none',
      },

      '& .MuiCollapse-container.MuiCollapse-entered': {},
      '& .MuiExpansionPanelSummary-root': {
        padding: '0',

        '&[aria-expanded="false"] svg': {
          transform: 'rotate(-90deg)',
        },
      },

      '& .MuiExpansionPanelDetails-root': {
        padding: '0',
      },
    },
  },
  tasksHeader: {
    borderBottom: '1px solid rgba(172,180,184,0.2)',
    paddingLeft: 16,
    paddingBottom: 20,
    marginBottom: 32,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 3,

    '& h2': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: '600',
      display: 'inline-block',
    },

    '& .filters': {
      display: 'flex',
      alignItems: 'center',

      '& .filter': {
        display: 'inline-block',
      },
      '& .MuiPaper-root': {
        padding: '0 !important',
      },
    },
  },
  addNewButton: {
    '& .main-button': {
      borderRadius: '0px',
      backgroundColor: '#252427',
      color: '#FFFFFF',
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 500,

      height: 40,
    },
    '& .more-button': {
      borderRadius: '0px',
      backgroundColor: '#252427',
      color: '#FFFFFF',

      fontSize: '12px',
      fontWeight: 500,

      height: 40,
    },
  },
  formControl: {
    display: 'inline-block',
    minWidth: '170px',
    marginRight: '12px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important',
      },
    },
  },

  heading: {
    fontSize: 16,
    fontWeight: 500,
    color: '#434343',

    '& svg': {
      fontSize: 25,
      verticalAlign: 'middle',
      marginRight: 8,
      transition: 'transform 0.2s ease-out',
    },
  },
  addNewForm: {
    '& .form-row': {
      'border-bottom': '1px solid rgba(172,180,184,0.2)',
      padding: '8px 16px 16px',
      'margin-bottom': '8px',
    },
    '& .title-field, .location': {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& input, & label': {
        'font-size': '24px',
        'line-height': '28px',
      },
      '& label': {
        color: '#9B9B9B',
      },
    },
    '& textarea': {
      width: '100%',
      minHeight: 150,
      fontFamily: 'inherit',
      fontSize: 14,

      border: 'none',
      outline: 'none',
      '&::placeholder': {
        color: '#9B9B9B',
      },
    },
    '& .new-options': {
      display: 'flex',
      justifyContent: 'flex-start',

      '& .MuiFormControl-root': {},
      '& .MuiFormControlLabel-root': {
        position: 'absolute',
        right: 0,
        flexDirection: 'row-reverse',
      },
    },
  },
 
});

const options = ['Task or Note', 'Guest Request', 'Work Order'];

const Tasks = ({
  tasks,
  hotel,
  getTasks,
  currentTask,
  tasksLoading,
  setCurrent,
  updateTask,
  user,
}) => {
  const { members, departments, id: hotelid } = hotel;
  const userId = user.credentials.userId;

  // useEffect(() => {
  //   if (currentTask) {
  //     // setEditTask(currentTask);
  //   }
  // }, [currentTask]);

  const filterByDateOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Overdue', value: 'overdue' },
  ];

  const filterByCategoryOptions = [
    { label: 'Tasks', value: 'task' },
    { label: 'Notes', value: 'note' },
  ];

  const filterByPriorityOptions = [
    { label: 'Urgent', value: 'urgent' },
    { label: 'Important', value: 'important' },
    { label: 'Normal', value: 'normal' },
    { label: 'Complaints', value: 'complaints' },
    { label: 'Closed',value: 'closed'}
  ];

  const classes = useStyles();
  const [addNew, setAddNew] = useState(false);


  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  // const [editTask, setEditTask] = useState(initialState);

  const [departmentDropdown, setDepartmentDropdown] = useState([]);

  const [filteredTasks, setFilteredTasks] = useState([]);
  const [todayTasks, setTodayTasks] = useState([]);
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [upcomingTasks, setUpcomingTasks] = useState([]);

  const [filters, setFilters] = useState({
    date: null,
    category: null,
    priority: null,
    department: null,
    private: false,
  });

  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    getTasks();
  }, []);

  useEffect(() => {
    console.log('filters changed', filters);
    // getTasks(filters);
    setFilteredTasks([])

    if (tasks !== null) {
      let filtering = tasks;

      if (filters.priority !== 'closed') {
        filtering = filtering.filter((task) => !task.complete)
      }

      if (filters.private === true) {
        filtering = filtering.filter((task) => {
          return (
            task.createdBy === userId || task.assignedTo.includes(userId)
          );
        });
      }

      if (filters.department !== null) {
        filtering = filtering.filter((task) => {
          return filters.department === task.department;
        });
      }

      if (filters.category !== null) {
        filtering = filtering.filter((task) => {
          return filters.category === task.category;
        });
      }

      if (filters.priority !== null) {
        filtering = filtering.filter((task) => {
          if (filters.priority === 'complaint') {
            return task.complaint === true;
          }

          if (filters.priority === 'closed') {
            return task.complete === true;
          }

          if (filters.priority === task.priority) {
            return task;
          }
        });
      }

      setFilteredTasks(filtering);
     
    }
  }, [filters, tasks]);

  useEffect(() => {
     filterTasksByDate();
  }, [filteredTasks]);


  const filterTasksByDate = () => {
    setTodayTasks([]);
    setOverdueTasks([]);
    setUpcomingTasks([]);

    let today = moment().startOf('day').toDate();
    const todayTasks = filteredTasks.filter((task) => {
      const dueDate = typeof task.dueDate === 'object' ? new Date(task.dueDate._seconds * 1000) : new Date(task.dueDate);
      return  dueDate.getFullYear() === today.getFullYear() &&
      dueDate.getMonth() === today.getMonth() &&
      dueDate.getDate() === today.getDate();
    });

    setTodayTasks(todayTasks);

    const overdueTasks = filteredTasks.filter((task) => {
      const dueDate = typeof task.dueDate === 'object' ? new Date(task.dueDate._seconds * 1000) : new Date(task.dueDate);
      return dueDate < today;
    });

    setOverdueTasks(overdueTasks);

    const upcomingTasks = filteredTasks.filter((task) => {
      const dueDate = typeof task.dueDate === 'object' ? new Date(task.dueDate._seconds * 1000) : new Date(task.dueDate);
      return dueDate > today;
    });

    setUpcomingTasks(upcomingTasks);
  }


  useEffect(() => {
    let departmentOptions = [];

    departments.forEach((dep) => {
      departmentOptions.push({
        label: dep.name,
        value: dep.id,
      });
      setDepartmentDropdown(departmentOptions);
    });
  }, [departments]);

  function toggleAddNew() {
    setAddNew(!addNew);
  }

  const handleClick = () => {
    if (!addNew) {
      toggleAddNew();
      setOpen(false);
    }
  };

  const handleAddNewClick = (event, index) => {
    console.log(event, index);

    if (!addNew) {
      toggleAddNew();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const openTask = (id) => {
    
    const taskToOpen = tasks.filter((item) => {
      return item.id === id;
    });
    console.log('open task', id, taskToOpen);
    setAddNew(true);
    setCurrent(taskToOpen[0]);
  };

  const setComplete = (id) => {
    const task = tasks.find((e) => {
      if (e.id === id) {
        updateTask(id, {
          complete: !e.complete
        });
      }
    });
    // task.complete = true;
    
  };

  return (
    <div className={classes.root + (addNew ? ' show-add' : '')}>
      <div className='tables'>
        <Paper>
          <div className={classes.tasksHeader}>
            <Typography variant='body1' component='h2'>
              Tasks & Notes
            </Typography>

            {!addNew && (
              <div className='filters'>
                <FilterMenu
                  value={filters.date}
                  onChange={(e) => filterChange('date', e)}
                  options={filterByDateOptions}
                  placeholder='Filter Date'
                />
                 <FilterMenu
                  value={filters.category}
                  onChange={(e) => filterChange('category', e)}
                  options={filterByCategoryOptions}
                  placeholder='Filter Category'
                />
                <FilterMenu
                  value={filters.priority}
                  onChange={(e) => filterChange('priority', e)}
                  options={filterByPriorityOptions}
                  placeholder='Filter Priority'
                />
                <FilterMenu
                  value={filters.department}
                  onChange={(e) => filterChange('department', e)}
                  options={departmentDropdown}
                  placeholder='All Departments'
                />

                <Switch
                  value={filters.private}
                  onChange={(e) => filterChange('private', e)}
                  label='Only My Tasks & Notes'
                />

               


                <Button action={() =>handleAddNewClick()}>Add New</Button>

              </div>
            )}
          </div>

          {tasksLoading && (
            'Loading tasks...'
          )}
          {!tasksLoading && (
          <Fragment>
{todayTasks.length > 0 &&
  (filters.date === 'today' || filters.date === null) && (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography className={classes.heading}>
          <Svg.Triangle />
          Today
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {!tasksLoading && tasks !== null && (
          <Fragment>
            <TasksTable
              tasks={todayTasks}
              members={members}
              departments={departments}
              addNew={addNew}
              openTask={openTask}
              setComplete={(id, status) => setComplete(id, status)}
            />
          </Fragment>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )}

{upcomingTasks.length > 0 &&
  (filters.date === 'upcoming' || filters.date === null) && (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography className={classes.heading}>
          {' '}
          <Svg.Triangle /> Upcoming
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {!tasksLoading && tasks !== null && (
          <Fragment>
            <TasksTable
              tasks={upcomingTasks}
              members={members}
              departments={departments}
              addNew={addNew}
            openTask={openTask}
            setComplete={(id, status) => setComplete(id, status)}
            />
          </Fragment>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )}

{overdueTasks.length > 0 &&
  (filters.date === 'overdue' || filters.date === null) && (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography className={classes.heading}>
          {' '}
          <Svg.Triangle /> Overdue
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {!tasksLoading && overdueTasks !== null && (
          <Fragment>
            <TasksTable
              tasks={overdueTasks}
              members={members}
              departments={departments}
              addNew={addNew}
            openTask={openTask}
            setComplete={(id, status) => setComplete(id, status)}
            />
          </Fragment>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )}
{(1 == 2) && (
    <ExpansionPanel>
  <ExpansionPanelSummary
    aria-controls='panel1a-content'
    id='panel1a-header'
  >
    <Typography className={classes.heading}>
      {' '}
      <ArrowDropDownIcon></ArrowDropDownIcon>All Tasks (no filters
      applied)
    </Typography>
  </ExpansionPanelSummary>
  <ExpansionPanelDetails>
    {!tasksLoading && tasks !== null && (
      <Fragment>
        <TasksTable
          tasks={tasks}
          members={members}
          departments={departments}
          addNew={addNew}
          openTask={openTask}
          setComplete={(id, status) => setComplete(id, status)}
        />
      </Fragment>
    )}
  </ExpansionPanelDetails>
</ExpansionPanel>
    )}     
    </Fragment> 
          )}
          
        
        </Paper>
      </div>

      <TaskEditor
        active={addNew}
        toggleAddNew={toggleAddNew}
        members={members}
        departments={departmentDropdown}
        priorityOptions={filterByPriorityOptions}
        closeTask={setComplete}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  tasks: state.tasks.tasks,
  tasksLoading: state.tasks.loading,
  hotel: state.hotel,
  user: state.user,
  currentTask: state.tasks.current,
});

export default connect(mapStateToProps, {
  getTasks,
  setCurrent,
  clearCurrent,
  updateTask,
})(Tasks);
