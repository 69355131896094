import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 142;

const MainViewStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paperAnchorLeft': {
      borderRight: 'none',
      // boxShadow: '5px 0 20px -5px rgba(172,180,184,0.2)'
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: 'transparent',
    padding: '0 !important',
    boxShadow: 'none !important'
  },
  toolbar: {
    padding: theme.spacing(3, 4),
    textAlign: 'center',
    '& h2': {
      fontWeight: 500,
    },
  },
  logo: {
    width: 'auto',
    height: 60,
    marginBottom: 10,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: '40px 40px 40px 0px',
    height: '100vh',
    // '& > *, & > * > *': {
    //   height: '100%'
    // }
  },
  menu: {
    paddingTop: 42,
    '& a': {
      lineHeight: '80px',
      textAlign: 'center',
      textDecoration: 'none',

      '& .MuiListItem-root': {
        textAlign: 'center',
        fontSize: '18px',
        lineHeight: '80px',
        height: '80px',
        justifyContent: 'center',
      },
      '& .MuiTypography-body1': {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',

        color: '#434343',
      },
      '& .MuiListItemIcon-root': {
        minWidth: 22,
      },
      '& svg': {
        width: 22,
        // height: 18
      },
      '&.active .MuiTypography-body1': {
        color: '#252427',
      },
      '&.active svg, &.active svg path': {
        fill: '#252427',
      },
    },
  },
  divider: {
    width: '80%',
    margin: '0 auto',
  },
  stremlindLogo: {
    position: 'absolute',
    bottom: '0',
    height: '90px',
    width: '100%',
    textAlign: 'center',
    padding: '0px 0px',

    '& .text': {
      color: '#D3D3D3',
      fontFamily: 'Alegreya Sans SC',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '19px',
      textAlign: 'center',
      padding: '35px 0px',
      display: 'block',
    },
  },

  stremlindBranding: {
    fontFamily: 'Alegreya Sans SC',
    fontSize: 16,
    fontWeight: 'bold',

    textAlign: 'center',
    color: '#d3d3d3',
    position: 'absolute',
    bottom: 36,
    left: 0,
    right: 0,
  },
}));

export default MainViewStyles;
