import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Button from '../../theme/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DateWidget from './DateWidget';
import * as Svg from '../../svg/icons';
import FormSelect from './FormSelect';
import FormSelectWithSwitch from './FormSelectWithSwitch';
import { connect } from 'react-redux';
import {
  addTask,
  clearCurrent,
  updateTask,
  setCurrent,
  getTaskActivity,
  postComment,
  getComments as getTaskComments,
  readComments
} from '../../store/actions/tasksActions';

import Switch from '../../theme/Switch';

import TextInput from './TextInput';
import CommentCreator from './CommentCreator';
import Comments from './Comments';
import Activity from './Activity';

import * as moment from 'moment';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .push-right': {
      position: 'absolute',
      right: 32,
    },
    '& .tables': {
      width: '100%',
    },


    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px',
      },
      '& .add-pane': {
        display: 'block',
      },
    },

    '& .MuiExpansionPanel-root': {
      padding: '0 !important',
      margin: '0 !important',
      boxShadow: 'none !important',
      '&:before': {
        display: 'none',
      },

      '& .MuiCollapse-container.MuiCollapse-entered': {
        marginTop: '-40px',
      },
      '& .MuiExpansionPanelSummary-root': {
        padding: '0',

        '&[aria-expanded="false"] svg': {
          transform: 'rotate(-90deg)',
        },
      },

      '& .MuiExpansionPanelDetails-root': {
        padding: '0',
      },
    },
  },
  tasksHeader: {
    borderBottom: '1px solid rgba(172,180,184,0.2)',
    marginBottom: 32,
    padding: '16px',
    paddingBottom: '16px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 3,

    '& h2': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: '600',
      display: 'inline-block',
    },

    '& .filters': {
      display: 'flex',
      alignItems: 'center',

      '& .filter': {
        display: 'inline-block',
      },
      '& .MuiPaper-root': {
        padding: '0 !important',
      },
    },
  },
  addNewButton: {
    '& .main-button': {
      borderRadius: '0px',
      backgroundColor: '#252427',
      color: '#FFFFFF',

      fontSize: '12px',
      fontWeight: 500,

      height: 40,
    },
    '& .more-button': {
      borderRadius: '0px',
      backgroundColor: '#252427',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: 40,
    },
  },
  formControl: {
    display: 'inline-block',
    minWidth: '170px',
    marginRight: '12px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important',
      },
    },
  },

  heading: {
    fontSize: 16,
    fontWeight: 500,
    color: '#434343',

    '& svg': {
      fontSize: 25,
      verticalAlign: 'bottom',
      transition: 'transform 0.2s ease-out',
    },
  },
  addNewForm: {
    marginBottom: 32,
    '& .form-row': {
      'border-bottom': '1px solid rgba(172,180,184,0.2)',
      padding: '8px 16px 16px',
      'margin-bottom': '8px',
    },
    '& .title-field': {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& input, & label': {
        'font-size': '24px',
        'line-height': '28px',
      },
      '& label': {
        color: '#9B9B9B',
      },
    },
    '& .location': {
      '& input, & label': {
        'font-size': '20px',
        'line-height': '23px',
      },
    },
    '& textarea': {
      width: '100%',
      minHeight: 150,
      fontFamily: 'inherit',
      fontSize: 14,

      border: 'none',
      outline: 'none',
      '&::placeholder': {
        color: '#9B9B9B',
      },
    },
    '& .new-options': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',

      '& .MuiFormControl-root': {},
      '& .MuiFormControlLabel-root': {
        position: 'absolute',
        right: 0,
        flexDirection: 'row-reverse',
      },
    },
  },
   footer: {
    position: 'absolute',
    bottom: 32,
    width: 'calc(100% - 64px)',
    paddingTop: 32,
     '& .view-switcher': {
       float: 'right',
       position: 'absolute',
       right: 0,
       top: 0,
       cursor: 'pointer'
     },
     '&.line-bottom': {
       borderBottom: '1px solid rgba(172,180,184,0.2)'
     }
  }
});

const TaskEditor = ({
  currentUserId,
  active,
  toggleAddNew,
  pane,
  departments,
  members,
  addTask,
  currentTask,
  setCurrent,
  getTaskActivity,
  activity,
  getTaskComments,
  postComment,
  comments,
  closeTask,
  updateTask,
  readComments
}) => {
  const classes = useStyles();
  const [formReady, setFormReady] = useState(false);
  const [viewMode, setViewMode] = useState('comments');
  
  const [editing, setEditing] = useState(true);
  const categories = [{label: 'Task', value: 'task'}, {label: 'Note', value: 'note'}]

  const initialState = {
    title: '',
    department: '',
    location: '',
    assignedTo: [],
    dueDate: '',
    priority: 'normal',
    isPrivate: false,
    complaint: false,
    message: '',
    isRecurringTask: false,
    recurringRepeat: null,
    category: null,
    complete: false
  };
  const [editTask, setEditTask] = useState(initialState);

  const onSubmit = (e) => {
    if (!formReady) {
    } else {
      const newTask = {
        title,
        category,
        department,
        location,
        assignedTo,
        dueDate,
        priority,
        isPrivate,
        complaint,
        message,
        isRecurringTask,
        recurringRepeat,
        complete
      };
      addTask(newTask);
      setEditTask(initialState);
    }
  };

  const {
    title,
    department,
    location,
    assignedTo,
    dueDate,
    priority,
    isPrivate,
    complaint,
    message,
    isRecurringTask,
    recurringRepeat,
    category,
    complete
  } = editTask;

  const priorityOptions = [
    { label: 'Normal', value: 'normal' },
    { label: 'Important', value: 'important' },
    { label: 'Urgent', value: 'urgent' },
  ];

  const onChange = (e) => {
    setEditTask({ ...editTask, [e.target.name]: e.target.value });
  };

  const onSelectChange = (name, e) => {
    setEditTask({ ...editTask, [name]: e });
  };

  const memberOptions = (members) => {
    const options = [];
    members.forEach((member) => {
      options.push({
        value: member.id,
        label: `${member.firstName} ${member.lastName
          .charAt(0)
          .toUpperCase()}.`,
      });
    });
    return options;
  };

  const switchView = () => {
    if (viewMode === 'comments') {
      setViewMode('activity');
    } else if (viewMode === 'activity') {
      setViewMode('comments');
    }
  }

  const createComment = (comment, inc) => {
    postComment(currentTask.id, comment)
    console.log('post comment', inc)
    if (inc) {
      updateTask(currentTask.id, {
        complete: false
      });
    }
  }

  useEffect(() => {
    if (
      title === '' ||
      department === '' ||
      location === '' ||
      dueDate === null ||
      category === null ||
      priority === ''
    ) {
      setFormReady(false);
    } else {
      setFormReady(true);
    }
  }, [editTask]);


  useEffect(() => {
    if (currentTask !== null) {
      currentTask.isPrivate = currentTask.private;
      setEditTask(currentTask);
      setEditing(currentUserId === currentTask.createdBy);
      getTaskComments(currentTask.id);
      if (Array.isArray(currentTask.readComments)) {
        readComments(currentTask.id, currentUserId);

      }
    }
  }, [currentTask])

  useEffect(() => {
    if (active === false) {
      setEditing(true);
      clearCurrent();
      setEditTask(initialState);
    }
  }, [active])

  const close = () => {
    toggleAddNew();
    setCurrent(null);
    
  }

  const setComplete = () => {
    updateTask(currentTask.id, {
      complete: !currentTask.complete
    });
  };

  const getDate = (dueDate) => {
    console.log(dueDate instanceof Date)
    return typeof dueDate !== 'object' ? new Date(dueDate) : new Date(dueDate._seconds * 1000);
  }

  return (
    <Paper
      className={`${classes.root} ${active ? 'add-pane show' : 'add-pane'}`}
    >
      <div className='header'>
        
        {editing && currentTask === null ? 
          (
          <Button
          variant='contained'
          color='primary'
          className='primary-button'
          disabled={!formReady}
          action={() => onSubmit()}
        >
              Create {category !== null ? category : 'Task or Note'}
        </Button>
          ): (currentTask !== null && (
             <Button
            variant='contained'
            color='primary'
            className='primary-button'
            action={() => setComplete()}
          >
              {currentTask.complete ? 'Reopen' : 'Mark as Complete'}
        </Button>)
          )
        }
        

        <div className='push-right'>
          <a className='delete'  style={{marginRight: 24}}>
            Delete
          </a>
          <a className='close' onClick={() => close()}>
            Close
          </a>
        </div>
      </div>

      <form className={classes.addNewForm} noValidate autoComplete='off'>
        <div className='form-row'>
          <div className='title-field'>
            <TextInput name='title' value={title} onChange={onChange} disabled={!editing} placeholder={'Write a short title for your task or note'}  />
          </div>
        </div>
        <div className='form-row'>
          <div className='new-options'>
            <FormSelect
              options={categories}
              value={category}
              name='category'
              icon={<Svg.Note />}
              onChange={(e) => onSelectChange('category', e)}
              label='Type'
               disabled={!editing}
            ></FormSelect>
            
            <DateWidget name='dueDate' value={dueDate}  onChange={onChange} disabled={!editing} icon={<Svg.Calendar />}>
              Due Date
            </DateWidget>

            <FormSelect
              options={departments}
              value={department}
              name='department'
              icon={<Svg.Department />}
              onChange={(e) => onSelectChange('department', e)}
              label='Department'
               disabled={!editing}
            ></FormSelect>

            <FormSelectWithSwitch
              options={priorityOptions}
              value={priority}
              switchValue={complaint}
              switchChange={(e) => {setEditTask({...editTask, ['complaint']: e})}}
              name='priority'
              label='Priority'
              icon={<Svg.Priority />}
              onChange={(e) => onSelectChange('priority', e)}
              disabled={!editing}
            ></FormSelectWithSwitch>

            <FormSelect
              options={memberOptions(members)}
              value={assignedTo}
              label='Assigned To'
              icon={<Svg.Assigned />}
              onChange={(e) => onSelectChange('assignedTo', e)}
              multiple
              name='assignedTo'
              disabled={!editing}
            ></FormSelect>

            <div className='push-right'>
              <Switch
                value={isPrivate}
                onChange={(e) => onSelectChange('isPrivate', e)}
                label={editing ? 'Mark as private' : 'Private'}
                name='isPrivate'
                disabled={!editing}
              />
            </div>
          </div>
        </div>
        <div className='form-row'>
          <div className='location'>
            <TextInput name='location' value={location} onChange={onChange} disabled={!editing} placeholder={'Room number or location'}  />
          </div>
        </div>

        <div className='form-row'>
          <div className='details'>
            <TextareaAutosize
              aria-label='empty textarea'
              value={message}
              name='message'
              onChange={onChange}
              placeholder={`Your task or note details`}
              disabled={!editing}
              style={{resize: 'vertical'}}
            />
          </div>
        </div>
      </form>

      {currentTask !== null && (
        <div className={classes.body}>
          {viewMode === 'comments' && (<Comments comments={comments} />)}
          {viewMode === 'activity' && (<Activity activityArray={currentTask.activities} category={currentTask.category} />)}
          
        </div>
      )}
     
      {currentTask !== null && (
      <div className={`${classes.footer} ${viewMode==='activity' ? 'line-bottom' : ''}`}>
        <div className="view-switcher" onClick={switchView}>{viewMode==='comments' ? 'Activity' : 'Comments'}</div>
        
        {viewMode === 'comments' && (<CommentCreator createComment={createComment} />)}
        </div>
        )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  currentTask: state.tasks.current,
  activity: state.tasks.activity,
  comments: state.tasks.comments,
  currentUserId: state.user.credentials.userId
});

export default connect(mapStateToProps, {
  addTask,
  clearCurrent,
  setCurrent,
  updateTask,
  getTaskActivity,
  postComment,
  getTaskComments,
  readComments
})(TaskEditor);
