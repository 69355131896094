import React, { useState } from 'react';
import {
  Input as ZendeskInput,
  Field as ZendeskField,
  Label as ZendeskLabel,
  Message,
} from '@zendeskgarden/react-forms';

import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& label': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '14px',
      color: theme.palette.text.primary,
      transition: 'color 0.2s linear',
    },

    '& input': {
      borderRadius: 0,
      border: 'none',
      borderBottom: '1px solid rgba(172,180,184,0.2)',
      padding: '0 0',
      lineHeight: '19px',
      fontSize: 16,
      boxShadow: 'none',
      outline: 'none',
      marginTop: '0 !important',

      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
        borderColor: 'rgba(172,180,184,0.2)',
      },
      '&:hover': {
        outline: 'none',
        boxShadow: 'none',
        borderColor: 'rgba(172,180,184,0.2)',
      },

      '&::placeholder': {
        color: '#AFAFB4',
      },
    },

    '& div.message': {
      position: 'absolute',
      top: '100%',
      left: 0,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 60,
      left: 0,
      width: '100%',
      borderBottom: '1px solid #252427',
      transition: 'transform 0.3s ease-out',
      transform: 'scaleX(0)',
    },

    '&.filled:not(.error)': {
      '& label': {
        color: '#252427',
      },
      '&:after': {
        transform: 'scaleX(1)',
      },
    },

    '&.error': {
      '& label, & input, & .message': {
        color: '#E94F30',
      },
      '&:after': {
        borderBottomColor: '#E94F30',
        transform: 'scaleX(1)',
      },
      color: '#E94F30',
    },
  },
}));

const LoginField = ({ className, label, value, message, error, ...rest }) => {
  const styles = Styles();
  const [focus, setFocus] = useState(false);
  const classNames = `${styles.root} ${className} ${error ? 'error' : null} ${
    value !== '' || focus ? 'filled' : ''
  } `;

  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = () => {
    setFocus(false);
  };

  return (
    <ZendeskField className={classNames}>
      <ZendeskLabel>{label}</ZendeskLabel>
      <ZendeskInput {...rest} onFocus={onFocus} onBlur={onBlur} />
      {error && <Message className='message'>{error}</Message>}
    </ZendeskField>
  );
};

export default LoginField;
