import React, { useEffect, useContext } from 'react';

const Logout = (props) => {
  const authContext = useContext();
  const { loadUser, logout, isAuthenticated } = authContext;

  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    loadUser();
    if (!isAuthenticated) {
      props.history.push('/');
    }
  }, [isAuthenticated]);

  return <div>Logging out...</div>;
};

export default Logout;
