import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function Rates({ rates }) {
  const difference = (oldRate, newRate) => {
    const diff = oldRate - newRate;
    const prefix = diff < 0 ? '-$' : '$';
    return `${prefix}${Math.abs(diff)}`;
  };

  return (
    <TableContainer>
      <Table style={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Room Type</TableCell>
            <TableCell>Current Year</TableCell>
            <TableCell>Last Year</TableCell>
            <TableCell>Difference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rates.map((row) => (
            <TableRow key={row.name}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell>${row.currentYearRate}</TableCell>
              <TableCell>${row.lastYearRate}</TableCell>
              <TableCell>
                {difference(row.lastYearRate, row.currentYearRate)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Rates;
