import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, SvgIcon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '../../theme/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import * as moment from 'moment';
import AnnouncementsTable from '../../layout/tables/Announcements/AnnouncementsTable';

import {
  getAnnouncements,
  setCurrent,
  clearCurrent,
  updateAnnouncement,
} from '../../store/actions/announcementsActions';

import * as Svg from '../../svg/icons';
import AnnouncementEditor from '../../layout/editors/AnnouncementEditor';

import FilterMenu from '../../layout/tables/FilterMenu';
import Switch from '../../theme/Switch';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%',


      '&.MuiPaper-root': {
        padding: '20px 32px !important'
      }
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 24px 150px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '32px',
        '& .close': {
          color: '#434343',
          fontWeight: 500,
          cursor: 'pointer',
        },
         '& .delete': {
          color: '#DE6552',
          fontWeight: 500,
          cursor: 'pointer',
        },
      },

    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px',
      },
      '& .add-pane': {
        display: 'block',
      },
    },
    '& .MuiExpansionPanelSummary-root.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: 0,
      transform: 'translateY(20px)',
    },

    '& .MuiExpansionPanel-root': {
      padding: '0 !important',
      margin: '0 !important',
      boxShadow: 'none !important',
      '&:before': {
        display: 'none',
      },

      '& .MuiCollapse-container.MuiCollapse-entered': {},
      '& .MuiExpansionPanelSummary-root': {
        padding: '0',

        '&[aria-expanded="false"] svg': {
          transform: 'rotate(-90deg)',
        },
      },

      '& .MuiExpansionPanelDetails-root': {
        padding: '0',
      },
    },
  },
  announcementsHeader: {
    borderBottom: '1px solid rgba(172,180,184,0.2)',
    paddingLeft: 16,
    paddingBottom: 20,
    marginBottom: 32,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 3,

    '& h2': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: '600',
      display: 'inline-block',
    },

    '& .filters': {
      display: 'flex',
      alignItems: 'center',

      '& .filter': {
        display: 'inline-block',
      },
      '& .MuiPaper-root': {
        padding: '0 !important',
      },
    },
  },
  addNewButton: {
    '& .main-button': {
      borderRadius: '0px',
      backgroundColor: '#252427',
      color: '#FFFFFF',
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 500,

      height: 40,
    },
    '& .more-button': {
      borderRadius: '0px',
      backgroundColor: '#252427',
      color: '#FFFFFF',

      fontSize: '12px',
      fontWeight: 500,

      height: 40,
    },
  },
  formControl: {
    display: 'inline-block',
    minWidth: '170px',
    marginRight: '12px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important',
      },
    },
  },

  heading: {
    fontSize: 16,
    fontWeight: 500,
    color: '#434343',

    '& svg': {
      fontSize: 25,
      verticalAlign: 'middle',
      marginRight: 8,
      transition: 'transform 0.2s ease-out',
    },
  },
  addNewForm: {
    '& .form-row': {
      'border-bottom': '1px solid rgba(172,180,184,0.2)',
      padding: '8px 16px 16px',
      'margin-bottom': '8px',
    },
    '& .title-field, .location': {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& input, & label': {
        'font-size': '24px',
        'line-height': '28px',
      },
      '& label': {
        color: '#9B9B9B',
      },
    },
    '& textarea': {
      width: '100%',
      minHeight: 150,
      fontFamily: 'inherit',
      fontSize: 14,

      border: 'none',
      outline: 'none',
      '&::placeholder': {
        color: '#9B9B9B',
      },
    },
    '& .new-options': {
      display: 'flex',
      justifyContent: 'flex-start',

      '& .MuiFormControl-root': {},
      '& .MuiFormControlLabel-root': {
        position: 'absolute',
        right: 0,
        flexDirection: 'row-reverse',
      },
    },
  },
 
});

const options = ['Announcement', 'Memo'];

const Announcements = ({
  announcements,
  hotel,
  getAnnouncements,
  currentAnnouncement,
  announcementsLoading,
  setCurrent,
  updateAnnouncement,
  user,
}) => {
  const { members, departments, id: hotelid } = hotel;
  const userId = user.credentials.userId;

  // useEffect(() => {
  //   if (currentTask) {
  //     // setEditTask(currentTask);
  //   }
  // }, [currentTask]);

  const filterByDateOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Overdue', value: 'overdue' },
  ];

  const filterByCategoryOptions = [
    { label: 'Announcements', value: 'announcement' },
    { label: 'Notes', value: 'note' },
  ];

  const filterByPriorityOptions = [
    { label: 'Urgent', value: 'urgent' },
    { label: 'Important', value: 'important' },
    { label: 'Normal', value: 'normal' },
    { label: 'Complaints', value: 'complaints' },
    { label: 'Closed',value: 'closed'}
  ];

  const classes = useStyles();
  const [addNew, setAddNew] = useState(false);


  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  // const [editTask, setEditTask] = useState(initialState);

  const [departmentDropdown, setDepartmentDropdown] = useState([]);

  const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);
  const [latestAnnouncements, setLatestAnnouncements] = useState([]);
  const [pastannouncements, setPastannouncements] = useState([]);
  const [upcomingAnnouncements, setUpcomingAnnouncements] = useState([]);

  const [filters, setFilters] = useState({
    date: null,
    category: null,
    priority: null,
    department: null,
    private: false,
  });

  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    getAnnouncements();
  }, []);

  useEffect(() => {
    console.log('filters changed', filters);
    // getTasks(filters);
    setFilteredAnnouncements([])

    if (announcements !== null) {
      let filtering = announcements;

      if (filters.priority !== 'closed') {
        filtering = filtering.filter((announcement) => !announcement.complete)
      }

      if (filters.private === true) {
        filtering = filtering.filter((announcement) => {
          return (
            announcement.createdBy === userId || announcement.assignedTo.includes(userId)
          );
        });
      }

      if (filters.department !== null) {
        filtering = filtering.filter((announcement) => {
          return filters.department === announcement.department;
        });
      }

      if (filters.category !== null) {
        filtering = filtering.filter((announcement) => {
          return filters.category === announcement.category;
        });
      }

      if (filters.priority !== null) {
        filtering = filtering.filter((announcement) => {
          if (filters.priority === 'complaint') {
            return announcement.complaint === true;
          }

          if (filters.priority === 'closed') {
            return announcement.complete === true;
          }

          if (filters.priority === announcement.priority) {
            return announcement;
          }
        });
      }

      setFilteredAnnouncements(filtering);
     
    }
  }, [filters, announcements]);

  useEffect(() => {
     filterAnnouncementsByDate();
  }, [filteredAnnouncements]);


  const filterAnnouncementsByDate = () => {
    setLatestAnnouncements([]);
    setPastannouncements([]);
    setUpcomingAnnouncements([]);

    let today = moment().startOf('day').toDate();
    const latestAnnouncements = filteredAnnouncements.filter((announcement) => {
      const dueDate = typeof announcement.dueDate === 'object' ? new Date(announcement.dueDate._seconds * 1000) : new Date(announcement.dueDate);
      return dueDate >= today;
    });

    setLatestAnnouncements(latestAnnouncements);

    const pastannouncements = filteredAnnouncements.filter((announcement) => {
      const dueDate = typeof announcement.dueDate === 'object' ? new Date(announcement.dueDate._seconds * 1000) : new Date(announcement.dueDate);
      return dueDate < today;
    });

    setPastannouncements(pastannouncements);

    
  }


  useEffect(() => {
    let departmentOptions = [];

    departments.forEach((dep) => {
      departmentOptions.push({
        label: dep.name,
        value: dep.id,
      });
      setDepartmentDropdown(departmentOptions);
    });
  }, [departments]);

  function toggleAddNew() {
    setAddNew(!addNew);
  }

  const handleClick = () => {
    if (!addNew) {
      toggleAddNew();
      setOpen(false);
    }
  };

  const handleAddNewClick = (event, index) => {
    console.log(event, index);

    if (!addNew) {
      toggleAddNew();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const openAnnouncement = (id) => {
    
    const announcementToOpen = announcements.filter((item) => {
      return item.id === id;
    });
    console.log('open announcement', id, announcementToOpen);
    setAddNew(true);
    setCurrent(announcementToOpen[0]);
  };

  const setComplete = (id) => {
    const announcement = announcements.find((e) => {
      if (e.id === id) {
        updateAnnouncement(id, {
          complete: !e.complete
        });
      }
    });
    // announcement.complete = true;
    
  };

  return (
    <div className={classes.root + (addNew ? ' show-add' : '')}>
      <div className='tables'>
        <Paper>
          <div className={classes.announcementsHeader}>
            <Typography variant='body1' component='h2'>
              Announcements & Memos
            </Typography>

            {!addNew && (
              <div className='filters'>
                <FilterMenu
                  value={filters.date}
                  onChange={(e) => filterChange('date', e)}
                  options={filterByDateOptions}
                  placeholder='Filter Date'
                />
                 <FilterMenu
                  value={filters.category}
                  onChange={(e) => filterChange('category', e)}
                  options={filterByCategoryOptions}
                  placeholder='Filter Category'
                />
                <FilterMenu
                  value={filters.priority}
                  onChange={(e) => filterChange('priority', e)}
                  options={filterByPriorityOptions}
                  placeholder='Filter Priority'
                />
                <FilterMenu
                  value={filters.department}
                  onChange={(e) => filterChange('department', e)}
                  options={departmentDropdown}
                  placeholder='All Departments'
                />

                <Switch
                  value={filters.private}
                  onChange={(e) => filterChange('private', e)}
                  label='My Department'
                />

               


                <Button action={() =>handleAddNewClick()}>Add New</Button>

              </div>
            )}
          </div>

          {announcementsLoading && (
            'Loading announcements...'
          )}
          {!announcementsLoading && (
          <Fragment>
{latestAnnouncements.length > 0 &&
  (filters.date === 'today' || filters.date === null) && (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography className={classes.heading}>
          <Svg.Triangle />
          Latest Announcements
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {!announcementsLoading && announcements !== null && (
          <Fragment>
            <AnnouncementsTable
              announcements={latestAnnouncements}
              members={members}
              departments={departments}
              addNew={addNew}
              openAnnouncement={openAnnouncement}
              setComplete={(id, status) => setComplete(id, status)}
            />
          </Fragment>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )}

{pastannouncements.length > 0 &&
  (filters.date === 'overdue' || filters.date === null) && (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography className={classes.heading}>
          {' '}
          <Svg.Triangle /> Past Announcements
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {!announcementsLoading && pastannouncements !== null && (
          <Fragment>
            <AnnouncementsTable
              announcements={pastannouncements}
              members={members}
              departments={departments}
              addNew={addNew}
            openAnnouncement={openAnnouncement}
            setComplete={(id, status) => setComplete(id, status)}
            />
          </Fragment>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )}
{(1 == 2) && (
    <ExpansionPanel>
  <ExpansionPanelSummary
    aria-controls='panel1a-content'
    id='panel1a-header'
  >
    <Typography className={classes.heading}>
      {' '}
      <ArrowDropDownIcon></ArrowDropDownIcon>All Announcements (no filters
      applied)
    </Typography>
  </ExpansionPanelSummary>
  <ExpansionPanelDetails>
    {!announcementsLoading && announcements !== null && (
      <Fragment>
        <AnnouncementsTable
          announcements={announcements}
          members={members}
          departments={departments}
          addNew={addNew}
          openAnnouncement={openAnnouncement}
          setComplete={(id, status) => setComplete(id, status)}
        />
      </Fragment>
    )}
  </ExpansionPanelDetails>
</ExpansionPanel>
    )}     
    </Fragment> 
          )}
          
        
        </Paper>
      </div>

      <AnnouncementEditor
        active={addNew}
        toggleAddNew={toggleAddNew}
        members={members}
        departments={departmentDropdown}
        priorityOptions={filterByPriorityOptions}
        closeAnnouncement={setComplete}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  announcements: state.announcements.announcements,
  announcementsLoading: state.announcements.loading,
  hotel: state.hotel,
  user: state.user,
  currentAnnouncement: state.announcements.current,
});

export default connect(mapStateToProps, {
  getAnnouncements,
  setCurrent,
  clearCurrent,
  updateAnnouncement,
})(Announcements);
