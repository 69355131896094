import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
// import confetti from '../../../public/images/confetti.png'
import '../../scss/views/Public.scss';

const homeStyles = makeStyles(() => ({
  bg: {
    backgroundImage: `url(/images/confetti.png)`,
  },
}));

const Home = () => {
  const styles = homeStyles();
  return (
    <div className={`${styles.bg} publicStyles`}>
      <div className='heroText'>
        <img className='logo' src='/images/logo.png' alt='' />

        <h1 className='title'>
          Streamline your hotel operation with{' '}
          <span className='brandName'>Strēmlīnd</span>
        </h1>

        <h2 className='subTitle'>
          Automate, organize, and simplify your daily hotel operation.
        </h2>

        <h1 className='signUpTitle'>
          Sign up now and get 6 months free trial!
        </h1>
      </div>

      <div className='signUpForm container d-flex justify-content-center'>
        <form className='d-flex flex-row bd-highlight mb-3'>
          <div className='form-group inputFields'>
            <input
              type='text'
              className='form-control'
              id='exampleInputText'
              aria-describedby='emailHelp'
              placeholder='Full Name'
            />
          </div>
          <div className='form-group inputFields'>
            <input
              type='email'
              className='form-control'
              id='exampleInputEmail1'
              placeholder='Email'
            />
          </div>
          <div className='form-group inputFields'>
            <input
              type='text'
              className='form-control'
              id='exampleInputText'
              placeholder='Name Of Your Hotel'
            />
          </div>
          <button type='submit' className='submitButton btn btn-primary'>
            Submit
          </button>
        </form>
      </div>

      <footer>
        <p>© 2019-2020 Stremlind, LLC | Los Angeles, California</p>
        <a href='https://twitter.com/stremlind' target='_blank'>
          <FontAwesomeIcon
            icon={faTwitter}
            className='icons fab animated fadeInLeft delay-1s'
          />
        </a>
        <a href='mailto:info@stremlind.com'>
          <FontAwesomeIcon
            icon={faEnvelope}
            className='icons fas fa-envelope animated fadeInRight delay-1s'
          />
          <i className='icons fas fa-envelope animated fadeInRight delay-1s'></i>
        </a>
      </footer>
    </div>
  );
};

export default Home;
