import { SET_HOTEL, HOTEL_ERROR, SET_HOTEL_LOADING } from '../types';

const initialState = {
  id: null,
  name: null,
  slug: null,
  summary: null,
  roomData: null,
  members: [],
  departments: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL:
      console.log('get-hotel', action.payload);
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        slug: action.payload.slug,
        // summary: JSON.parse(action.payload.summary),
        roomData: action.payload.roomData,
        members: action.payload.members,
        departments: action.payload.departments,
        loading: false,
      };
    case SET_HOTEL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case HOTEL_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
