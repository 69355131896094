import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import userReducer from './reducers/userReducer';
import hotelReducer from './reducers/hotelReducer';
import hotelLoginReducer from './reducers/hotelLoginReducer';
import tasksReducer from './reducers/tasksReducer';
import announcementsReducer from './reducers/announcementsReducer';
import overviewReducer from './reducers/overviewReducer';
import rateComparisonReducer from './reducers/rateComparisonReducer';
// import dataReducer from './reducers/dataReducer';
// import uiReducer from './reducers/uiReducer';

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  user: userReducer,
  hotel: hotelReducer,
  hotelLogin: hotelLoginReducer,
  tasks: tasksReducer,
  announcements: announcementsReducer,
  overview: overviewReducer,
  rateComparison: rateComparisonReducer,
  // data: dataReducer,
  // UI: uiReducer,
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;
