import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Overview from '../dashboard/Overview';
import Announcements from '../dashboard/Announcements';
import Tasks from '../dashboard/Tasks';
import Checklist from '../dashboard/Checklist';
import Requests from '../dashboard/Requests';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  menu: {
    position: 'relative',
    zIndex: 0,
    overflowY: 'visible !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-30px',
      left: '-40px',
      width: 'calc(100% + 80px)',
      height: '254px',
      // backgroundImage: `url('images/menu-bg.png')`,
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: '100% auto',
      // backgroundPosition: 'bottom left',
      zIndex: 0,
    },
    '& .MuiTabs-fixed, .MuiTabs-root': {
      overflow: 'visible !important',
    },

    '& .MuiTabs-flexContainer': {
      display: 'inline-block',
      background: '#fff',
      boxShadow: '0 15px 30px -10px rgba(172,180,184,0.2)',

      '& .MuiTab-root': {
        zIndex: 2,
        textTransform: 'none',
        color: '#797B88',
        '&.Mui-selected': {
          color: '#434343',
        },

        '& .MuiTab-wrapper': {
          width: 'initial',
          '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            bottom: '-2px',
            borderBottom: '2px solid #252427',
          },
        },
      },
    },
    '& .MuiTabs-indicator': {
      bottom: 0,
      height: 4,
      background: '#252427',
      borderRadius: 0,
      zIndex: 1,
      transform: 'scaleX(0.35)',
    },
  },
  main: {
    position: 'relative',
    zIndex: 1,
    paddingTop: 40,
  },
});

const Dashboard = ({ match }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Route exact path={`${match.url}`} component={Overview} />
        <Route path={`${match.url}/announcements`} component={Announcements} />

        <Route path={`${match.url}/tasks-and-notes`} component={Tasks} />

        <Route path={`${match.url}/checklist`} component={Checklist} />

        <Route path={`${match.url}/requests`} component={Requests} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.hotel,
});
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Dashboard);
