import React from 'react';
import LoginStyles from '../../views/Login.styles';
import LoginButton from '../../components/LoginButton';
import { connect } from 'react-redux';

const RegistrationSuccess = ({ history, hotelSlug }) => {
  const styles = LoginStyles();

  const onClick = () => {
    console.log('go to app', hotelSlug);
    history.push(`/${hotelSlug}/`);
  };
  return (
    <div className={styles.wrapper}>
      <div className='success-screen'>
        <img
          src='/images/register-success-illustration.png'
          alt=''
          className='illustration'
        />
        <h2>Success!</h2>
        <p>We have sent you a confirmation email.</p>

        <LoginButton onClick={onClick}>Get Started</LoginButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hotelSlug: state.hotel.slug,
});

export default connect(mapStateToProps)(RegistrationSuccess);
