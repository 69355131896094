import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Home from '../pages/public/Home';

const PublicStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'flex-start',
  //   padding: '120px 50px',
  //   textAlign: 'center',
  //   '& img': {
  //     maxWidth: 400,
  //     width: '100%',
  //     marginBottom: 50,
  //   },
  //   '& .links': {
  //     position: 'fixed',
  //     bottom: 0,
  //     right: 0,
  //     '& a': {
  //       marginRight: 8,
  //     },
  //   },
  // },
}));

const Public = () => {
  // const styles = PublicStyles();
  const [rotate, setRotate] = useState(false);
  // const [timer, setTimer] = useState(null);

  // useEffect(() => {
  //   let timer = null;
  //   setTimeout(() => {
  //     timer = setInterval(() => {
  //       if (!rotate) {
  //         logoClick();
  //       }
  //     }, 5000);
  //   }, 120000);

  //   return () => {
  //     // clean up
  //     clearInterval(timer);
  //   };
  // }, []);

  // const logoClick = () => {
  //   setRotate(true);
  //   setTimeout(() => {
  //     setRotate(false);
  //   }, 1500);
  // };
  return (
    <div>
      <Home></Home>
      {/*<img
        src='/images/logo.png'
        alt='STRĒMLĪND logo'
        className={rotate ? 'rotating' : ''}
        onClick={logoClick}
      />
      <h1>STRĒMLĪND is coming soon to a hotel near you!</h1>

      <div className='links'>
        <Link to='/best-western-central-omaha/login'>Login</Link>
      </div>*/}
    </div>
  );
};

export default Public;
