import {
  GET_TASKS,
  ADD_TASK,
  UPDATE_TASK,
  SET_CURRENT_TASK,
  CLEAR_CURRENT_TASK,
  TASKS_ERROR,
  SET_TASKS_LOADING,
  GET_ACTIVITY,
  POST_COMMENT,
  LIKE_COMMENT,
  READ_COMMENT,
  GET_COMMENTS,
  UPDATE_COMMENT,
  DELETE_COMMENT
} from '../types';
import axios from 'axios';

export const getTasks = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const tasks = await axios.get(`/hotel/tasks/`);
    dispatch({
      type: GET_TASKS,
      payload: tasks.data,
    });
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err,
    });
  }
};

export const addTask = (task) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await axios.post('/hotel/task/create', task);

    dispatch({
      type: ADD_TASK,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err,
    });
  }
};

export const updateTask = (id, task) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/task/${id}/update`, task);

    dispatch({
      type: UPDATE_TASK,
      payload: {
        id: id,
        task: res.data
      },
    });
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err,
    });
  }
};

export const deleteTask = (taskId, commentId) => async (dispatch) => {
  // try {
  //   const res = await axios.post(`/hotel/tasks/comment/delete`, {
  //     taskId: taskId,
  //     commentId: commentId
  //   });
  //   dispatch({
  //     type: DELETE_COMMENT,
  //     payload: commentId
  //   })
  // } catch (err) {
  //   dispatch({
  //     type: TASKS_ERROR,
  //     payload: err
  //   })
  // }
}

// Set current task
export const setCurrent = (task) => {
  return {
    type: SET_CURRENT_TASK,
    payload: task,
  };
};

// Clear current task
export const clearCurrent = () => {
  return {
    type: CLEAR_CURRENT_TASK,
  };
};

export const getTaskActivity = (taskId) => async (dispatch) => {
  try {
    const res = await axios.get(`/hotel/tasks/${taskId}/activities`);

    dispatch({
      type: GET_ACTIVITY,
      payload: res
    })
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err
    })
  }
}


export const setLoading = () => {
  return {
    type: SET_TASKS_LOADING,
  };
};


export const postComment = (taskId, comment) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/tasks/comment/create`, {
      taskId: taskId,
      comment: comment
    });
    dispatch({
      type: POST_COMMENT,
      payload:  res.data
    })
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err
    })
  }
}

export const updateComment = (taskId, commentId, comment) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/tasks/comment/update`, {
      taskId: taskId,
      commentId: commentId,
      comment: comment
    });
    dispatch({
      type: UPDATE_COMMENT,
      payload: {
        commentId: commentId,
        comment: comment
      }
    })
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err
    })
  }
}

export const likeComment = (taskId, commentId) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/tasks/comment/like`, {
      taskId: taskId,
      commentId: commentId
    });
    dispatch({
      type: LIKE_COMMENT,
      payload: res.data
    })
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err
    })
  }
}

export const deleteComment = (taskId, commentId) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/tasks/comment/delete`, {
      taskId: taskId,
      commentId: commentId
    });
    dispatch({
      type: DELETE_COMMENT,
      payload: commentId
    })
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err
    })
  }
}

export const readComments = (taskId, userId) => async (dispatch) => {
  try {
    const res = await axios.post(`/hotel/tasks/comment/read`, {
      taskId: taskId,
    });
    dispatch({
      type: READ_COMMENT,
      payload: {
        id: taskId,
        userId: userId
      }
    })
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err
    })
  }
}


export const getComments = (taskId) => async (dispatch) => {
  try {
    const res = await axios.get(`/hotel/tasks/${taskId}/comments`);
    dispatch({
      type: GET_COMMENTS,
      payload: res.data
    })
  } catch (err) {
    dispatch({
      type: TASKS_ERROR,
      payload: err
    })
  }
}

// export const getComment = (taskId, commentId) => async (dispatch) => {
//   try {
//     const res = await axios.get(`/hotel/tasks/${taskId}/comment/${commentId}`);
//     dispatch({
//       type: GET_COMMENTS,
//       payload: res
//     })
//   } catch (err) {
//     dispatch({
//       type: TASKS_ERROR,
//       payload: err
//     })
//   }
// }
