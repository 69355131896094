import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',

    '& tr': {
      '& td:first-child': {
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          width: 8,
          top: 0,
          height: '100%',
          left: '-32px'
        }

      }
    },

    '& .eval-lower td:first-child:after': {
      backgroundColor: 'rgba(30,185,114,0.2)'
    },
    '& .eval-even td:first-child:after': {
      backgroundColor: 'rgba(241,174,45,0.2)'
    },
    '& .eval-higher td:first-child:after': {
      backgroundColor: 'rgba(233,79,48,0.2)'
    },
    '& .eval-lower .status-color, ': {
      color: '#1EB972'
    },
    '& .eval-even .status-color, ': {
      color: '#F1AE2D'
    },
    '& .eval-higher .status-color, ': {
      color: '#E94F30'
    }
  },
});

function createData(name, ourRate, theirRate, difference, evaluation) {
  return { name, ourRate, theirRate, difference, evaluation };
}

const rows = [
  createData('Best Western Canoga Park', '$120', '$120', '$0', 'Even'),
  createData('Hampton Inn Woodland Hills', '$120', '$140', '-$20', 'Lower'),
  createData('The Knights Inn', '$120', '$220', '-$100', 'Lower'),
  createData('Extended Stay', '$120', '$100', '$20', 'Higher'),
  createData('Extended Stay America', '$120', '$100', '$20', 'Higher'),
  createData('818 Hotel + Pool', '$120', '$100', '$20', 'Higher'),
  createData('Warner Center Marriott', '$120', '$100', '$20', 'Higher'),
  createData('Courtyard by Marriott', '$120', '$100', '$20', 'Higher'),
  createData('Holiday Inn', '$120', '$100', '$20', 'Higher'),
];

function HotelRates() {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Our Rate Today</TableCell>
            <TableCell>Competitor Rate</TableCell>
            <TableCell>Difference</TableCell>
            <TableCell>Our Rate Is</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}  className={`eval-${row.evaluation.toLowerCase()}`}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.ourRate}</TableCell>
              <TableCell>{row.theirRate}</TableCell>
              <TableCell className="status-color">{row.difference}</TableCell>
              <TableCell className="status-color">{row.evaluation}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default HotelRates;
