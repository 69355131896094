import React from 'react';

const HowToLogin = () => {
  return (
    <div>
      Put your hotel in the url to login. Test hotel is{' '}
      <a href='/best-western/login'>Best Western</a>
    </div>
  );
};

export default HowToLogin;
