import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Svg from '../../svg/icons';
import useOutsideClick from '../../utils/useClickOutside';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    marginRight: 24,
    userSelect: 'none',
    '& label': {
      display: 'flex',
      alignItems: 'center',
      padding: '6px 0',
      cursor: 'pointer',
      '& svg': {
        marginRight: 8,
      },
    },
  },
  disabled: {
    pointerEvents: 'none'
  },
  dropdown: {
    position: 'absolute',
    minWidth: 175,
    left: 0,
    top: '100%',
    transition: 'opacity 0.1s linear, transform 0.3s ease-out',
    opacity: 0,
    transform: 'translateY(-10px)',
    pointerEvents: 'none',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 15px 30px -10px rgba(158,158,158,0.3)',
    zIndex: 20,
    '&.open': {
      opacity: 1,
      transform: 'translateY(0px)',
      pointerEvents: 'all',
    },

    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      padding: '10px 16px',
      fontSize: 14,
      lineHeight: '16px',
      transition: 'all 0.2s linear',

      '& svg': {
        height: 16,
        float: 'right',
        '& path': {
          fill: '#252427',
          transition: 'all 0.2s linear',
        },
      },

      '&:hover': {
        backgroundColor: '#252427',
        color: 'white',
        '& svg path': {
          fill: 'white',
          transition: 'all 0.2s linear',
        },
      },
    },
  },
});
const FormSelect = ({
  multiple,
  options,
  value,
  icon,
  label,
  onChange,
  viewMode,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const [labelText, setLabel] = useState('');

  useEffect(() => {
    evalLabel(value);
  }, [value]);


  const evalLabel = (value) => {
  let str = '';
    if (multiple) {
      if (value.length > 0 && options.length > 0) {
        console.log('is multiple', value, options)
        const matchingOptions = options.filter((item) => value.includes(item.value));

        if (matchingOptions.length > 0) {
          matchingOptions.forEach((item, index) => {
            index === 0 ? (str = item.label) : (str = str + `, ` + item.label);
          });
        } else {
          str = label;
        }

        setLabel(str);
      } else {
        const str = label;
        setLabel(str);
      }
    } else {
      if (value && options.length > 0) {
        const matchingOptions = options.find((option) => option.value === value);
        if (matchingOptions) {
          str = matchingOptions.label;
        }
        setLabel(str);
      } else {
        const str = label;
        setLabel(str);
      }
    }
    return str;
  }

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const selectOption = (option) => {
    let newValue;
    if (multiple) {
      if (value && value.some((item) => item === option.value)) {
        newValue = value.filter((item) => {
          return item !== option.value;
        });
      } else {
        newValue = value;
        newValue.push(option.value);
      }

      onChange(newValue);
    } else {
      if (value === option.value) {
        newValue = null;
      } else {
        newValue = option.value;
        setLabel(option.label);
      }
      onChange(newValue);
    }

    evalLabel(value);
  };

  const optionIsSelected = (option) => {
    if (multiple) {
      if (value) {
        if (value.some((item) => item === option.value)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (value === option.value) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div className={`${classes.root} ${disabled ? classes.disabled : ''}`} ref={ref}>
      <label onClick={() => toggleOpen()}>
        {icon} {labelText}
      </label>

      <div className={`${classes.dropdown} ${open ? 'open' : ''}`}>
        <ul>
          {options.map((option) => (
            <li
              key={option.value}
              value={option.value}
              onClick={() => selectOption(option)}
            >
              {option.label} {optionIsSelected(option) ? <Svg.Checkmark /> : ''}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FormSelect;
