import {
  GET_LOGIN_HOTEL,
  SET_HOTEL_LOGIN_ERRORS,
  CLEAR_HOTEL_LOGIN_ERRORS,
} from '../types';
import axios from 'axios';

export const checkHotelSlug = (slug) => (dispatch) => {
  axios
    .get(`/hotelSlug/${slug}`)
    .then((res) => {
      dispatch({
        type: GET_LOGIN_HOTEL,
        payload: res.data,
      });
      dispatch({
        type: CLEAR_HOTEL_LOGIN_ERRORS,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_HOTEL_LOGIN_ERRORS,
        payload: err,
      });
    });
};
