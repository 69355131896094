import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';

function Schedule() {
  let { url, path } = useRouteMatch();
  return (
    <div>
      Schedule
      {(url, path)}
    </div>
  );
}

export default Schedule;
