import {
  GET_RATE_COMPARISON,
  RATE_COMPARISON_ERROR,
  RATE_COMPARISON_LOADING
} from '../types';

const initialState = {
  hotels: {},
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RATE_COMPARISON:
      return {
        ...state,
        hotels: action.payload,
        loading: false,
      };
    case RATE_COMPARISON_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RATE_COMPARISON_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
