import React, {useState} from 'react'
import Switch from '../../theme/Switch';
import makeStyles from '../../theme/makeStyles';
import Button from '../../theme/Button';
const useStyles = makeStyles({
  root: {

  },
  base: {

  },
  input: {
    height: 30,
    width: '100%',
    marginLeft: 16,
    border:' 1px solid rgba(172, 180, 184, 0.203753)',

    '& textarea': {
      border: 'none',
      width: '100%',
      height: '100%',
      fontFamily: 'inherit',
      outline: 'none',
      resize: 'none'
    }
  },
  tools: {
    display: 'none',
    '&.show': {
      display: 'block'
    }
  }
  
});

const CommentCreator = ({ createComment }) => {
  const classes = useStyles();
  // let comment = '';
  const [comment, setComment] = useState('')
  const [incomplete, setIncomplete] = useState(false);
  const [focus, setFocus] = useState(false);

  const clickPost = () => {
    setComment('');
    setIncomplete(false)
    createComment(comment, incomplete)
  }

  const focusText = () => {
    setFocus(true);
  }
  const blurText = () => {
    setFocus(false);
  }
  return (
    <div className={classes.root}>
      <div className={classes.base} style={{display: 'flex'}}>
         <div className="user-image" style={{borderRadius: 100, width: 30, height: 30, background: 'gray'}}>
        
      </div>
      <div className={classes.input}>
          <textarea value={comment} onChange={(e) => { setComment(e.target.value) }} onFocus={() => focusText()}/>
      </div>
      </div>
     
      <div className={`${classes.tools} ${focus ? 'show': ''}`}>
          <Switch
                value={incomplete}
                onChange={() => {setIncomplete(!incomplete)}}
                label='Mark task Incomplete'
              />
      <Button action={() => {clickPost()}}>Post</Button>
      </div>
    
    </div>
  )
}

export default CommentCreator;
