import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Schedule from '../../layout/tables/Overview/Schedule';
import Rates from '../../layout/tables/Overview/Rates';
import Events from '../../layout/tables/Overview/Events';
import HotelRates from '../../layout/tables/Overview/HotelRates';
import * as Svg from '../../svg/icons';
import { useEffect } from 'react';

import {getOverview} from '../../store/actions/overviewActions';
import {getRateCompare, getRateCompareTest} from '../../store/actions/rateComparisonActions';

const useStyles = makeStyles({
  root: {},
  heading: {
    background: 'white',
    padding: '30px 24px',
    marginBottom: 24,
    '& h2': {
      fontWeight: '500',
    },
  },
  stats: {
    '& .MuiPaper-root': {
      padding: '22px 32px 17px',
    },

    '& svg': {
      marginBottom: 21,
      width: 24,
    },

    '& .value': {
      fontWeight: 'bold',
      fontSize: 32,
      lineHeight: '37px',
      marginBottom: 20,
    },
    '& h3': {
      color: '#B8B4CC',
      fontSize: 16,
      fontWeight: 500,
      marginBottom: '0px !important',
    },
  },
});

const Overview = ({ hotel: { roomData, name }, getOverview, overview, overviewLoading, overviewError, getRateCompare, getRateCompareTest, hotelRates, hotelRatesLoading, hotelRatesError }) => {
  useEffect(() => {
    // getHotel();]
    getOverview();
    getRateCompare();
    getRateCompareTest();
  }, []);

  const loading = false;

  const classes = useStyles();
  // const dashboardContext = useContext(DashboardContext);
  return (
    <div className={classes.root}>
      {overviewLoading && (
        <Fragment>Loading overview...</Fragment>
      )}
      {overview !== null && !overviewLoading && !overviewError && (
        <Fragment>
          <Box className={classes.heading}>
            <Typography variant='body1' component='h2'>
              Today's Overview ({name})
            </Typography>
          </Box>
          <Grid container spacing={3} className={classes.stats}>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Svg.Announcements></Svg.Announcements>

                <div className='value'>{overview.announcement}</div>
                <Typography variant='body1' component='h3'>
                  Announcements
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Svg.Tasks></Svg.Tasks>
                <div className='value'>{overview.tasknote}</div>
                <Typography variant='body1' component='h3'>
                  Tasks & Notes
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Svg.Requests></Svg.Requests>
                <div className='value'>{overview.guestrequest}</div>
                <Typography variant='body1' component='h3'>
                  Total Requests
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Svg.Efficiency></Svg.Efficiency>
                <div className='value'>{overview.weekly_efficiency}</div>
                <Typography variant='body1' component='h3'>
                  Weekly Efficiency
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Svg.Guests></Svg.Guests>
                <div className='value'>{overview.inhousegues}</div>
                <Typography variant='body1' component='h3'>
                  In-House Guest
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Svg.Arrivals></Svg.Arrivals>
                <div className='value'>{overview.arrivals}</div>
                <Typography variant='body1' component='h3'>
                  Arrivals
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Svg.Departures></Svg.Departures>
                <div className='value'>{overview.departures}</div>
                <Typography variant='body1' component='h3'>
                  Departures
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Svg.Forecast></Svg.Forecast>
                <div className='value'>{overview.service_assurance}</div>
                <Typography variant='body1' component='h3'>
                  Service Level Assurance
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Fragment>
      )}

      <Grid container spacing={3}>
        <Fragment>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant='body1' component='h3'>
                Hotel Rate Comparison
              </Typography>
              {/* <HotelRates /> */}
            </Paper>
          </Grid>
        </Fragment>
        {!loading && roomData !== null && (
          <Fragment>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography variant='body1' component='h3'>
                  Our Rates Today
                </Typography>
                {/* <Rates rates={roomData} /> */}
              </Paper>
            </Grid>
          </Fragment>
        )}

        {!loading !== null && (
          <Fragment>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography variant='body1' component='h3'>
                  On Leave Today
                </Typography>
                <Schedule></Schedule>
              </Paper>
            </Grid>
          </Fragment>
        )}

        {!loading !== null && (
          <Fragment>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant='body1' component='h3'>
                  Groups & Events
                </Typography>
                <Events />
              </Paper>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.hotel,
  overview: state.overview.overview,
  overviewLoading: state.overview.loading,
  overviewError: state.overview.error,
  hotelRates: state.rateComparison.hotels,
  hotelRatesLoading: state.rateComparison.loading,
  hotelRatesError: state.rateComparison.error
});

export default connect(mapStateToProps, {getOverview, getRateCompare, getRateCompareTest})(Overview);
