import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
});

function createData(name, checkIn, totalNights) {
  return { name, checkIn, totalNights };
}

const rows = [
  createData('Ricky Austin', 'Oct 15, 2019', '2'),
  createData('Brett Francis', 'Oct 15, 2019', '5'),
  createData('Genevieve Sparks', 'Oct 15, 2019', '7'),
  createData('Millie Wilson', 'Oct 15, 2019', '4'),
];

function Events() {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Check In Date</TableCell>
            <TableCell>Total Nights</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">{row.name}</TableCell>
              <TableCell>{row.checkIn}</TableCell>
              <TableCell>{row.totalNights}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Events;
