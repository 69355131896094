import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 142;

const LoginViewStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minHeight: '100vh',
    padding: '115px 150px',
  },
  wrapper: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 15px 30px -10px rgba(172,180,184,0.2)',
    alignItems: 'stretch',
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    maxWidth: 1150,
    maxHeight: 900,
    '& .left-pane': {
      background: '#252427',
      padding: '40px 32px',
      color: '#fff',
      maxWidth: '475px',
      width: 475,
      '& .brand img': {
        marginBottom: 36,
      },
      '& p': {
        maxWidth: 260,
        marginBottom: 56,
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '24px',
      },
      '& img.illustration': {
        width: '100%',
        height: 'auto',
        maxWidth: 320,
        display: 'block',
        margin: '0 auto'
      },
    },
    '& .right-pane': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: '50px',
      flexGrow: 1,

      '& .login-header': {
        position: 'absolute',
        top: 40,
        right: 40,
        textAlign: 'right',
        fontSize: 14,
        fontWeight: 400,
        color: '#AFAFB4',
        '& a': {
          fontWeight: 500,
        },
      },
    },
    '& .success-screen': {
      flexGrow: 1,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',

      '& img': {
        width: 'auto',
        height: 'auto',
        marginBottom: 48,
      },
      '& h2': {
        fontSize: 40,
        fontWeight: 500,
        marginBottom: 20,
      },
      '& p': {
        fontSize: 24,
        lineHeight: '32px',
        marginBottom: 60,
      },
      '& button': {
        maxWidth: 380,
      },
    },

    '& .login-options': {
      '& .forgot-password': {
        float: 'right',
        color: '#AFAFB4',
        fontSize: 14,
        letterSpacing: 0,
      },

      '& input:checked ~ label:before': {
        borderColor: '#252427 !important',
        backgroundColor: '#252427 !important',
      },
      '& input:checked ~ label': {
        color: '#252427 !important',
      },

      '& label': {
        color: '#AFAFB4',
        '&:before': {
          borderRadius: 0,
        },
      },
    },

    '& .agreement-box': {
      marginBottom: 32,
      '& label': {
        color: '#434343 !important',
        fontSize: 12,
        lineHeight: '14px',
        '&:before': {
          borderRadius: 0,
        },
      },
      '& input:checked ~ label': {
        color: '#434343 !important',
      },

      '& input:checked ~ label:before': {
        borderColor: '#252427 !important',
        backgroundColor: '#252427 !important',
      },
    },
  },
  form: {
    maxWidth: 380,
    flexGrow: 1,

    '& .form-title': {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '24px',
      marginBottom: 52,
    },
  },
}));

export default LoginViewStyles;
