import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginRight: 24,
    '& label': {
      fontSize: 12
    }
  },
  disabled: {
    pointerEvents: 'none'
  },
  switch: {
    borderRadius: 12,
    backgroundColor: ' #d8d8d8',
    width: 32,
    height: 16,
    display: 'inline-block',
    margin: '0px 8px',
    verticalAlign: 'middle',
    position: 'relative',

    '& em': {
      content: '',
      height: 10,
      width: 10,
      backgroundColor: '#FFFFFF',
      borderRadius: 50,
      display: 'block',
      zIndex: 1,
      left: 0,
      top: 0,
      position: 'absolute',
      bottom: 0,
      margin: 'auto 3px',
      right: 0,
    },

    '&.active': {
      backgroundColor: '#DE6552',
      '& em': {
        transform: 'translateX(16px)',
      },
    },
  },
});
const Switch = ({ value, label, onChange, disabled }) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} ${disabled ? classes.disabled : ''}`}
      onClick={() => {
        onChange(!value);
      }}
    >
      <label>{label && label}</label>
      <div className={`${classes.switch} ${value ? 'active' : ''}`}>
        <em></em>
      </div>
    </div>
  );
};

export default Switch;
