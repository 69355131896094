import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Svg from '../../svg/icons';
import useOutsideClick from '../../utils/useClickOutside';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    marginRight: 24,
    userSelect: 'none',
    '& label': {
      display: 'flex',
      alignItems: 'center',
      padding: '6px 0',
      cursor: 'pointer',
      fontSize: 12,
      '& .triangle': {
        marginLeft: 8,
      }
    },
  },
  clear: {
    display: 'inline-block',
    width: 14,
    height: 14,
    borderRadius: '100%',
    textAlign: 'center',
    fontSize: 14,
    verticalAlign: 'middle',
    lineHeight: '14px',
    border: '1px solid red',
    color: 'red',
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textIndent: '-1px'
  },
  dropdown: {
    position: 'absolute',
    minWidth: 175,
    left: -16,
    top: '100%',
    transition: 'opacity 0.1s linear, transform 0.3s ease-out',
    opacity: 0,
    transform: 'translateY(-10px)',
    pointerEvents: 'none',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 15px 30px -10px rgba(158,158,158,0.3)',
    '&.open': {
      opacity: 1,
      transform: 'translateY(0px)',
      pointerEvents: 'all',
    },

    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      padding: '10px 16px',
      fontSize: 12,
      lineHeight: '16px',
      transition: 'all 0.2s linear',

      '& svg': {
        height: 16,
        float: 'right',
        '& path': {
          fill: '#252427',
          transition: 'all 0.2s linear',
        },
      },

      '&:hover': {
        backgroundColor: '#252427',
        color: 'white',
        '& svg path': {
          fill: 'white',
          transition: 'all 0.2s linear',
        },
      },
    },
  },
});

const FilterMenu = ({ placeholder, value, defaultOption, options, onChange }) => {
  const classes = useStyles();
  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const [label, setLabel] = useState(placeholder);

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let str = '';
    // console.log('v length', value.length);
    // if (value.length) {
    //   value.forEach((option, index) => {
    //     index === 0 ? (str = option.label) : (str = str + `, ` + option.label);
    //   });
    //   setLabel(str);
    // } else {
    //   setLabel(placeholder ? placeholder : 'nothing selected');
    // }

    if (!value) {
      setLabel(placeholder ? placeholder : 'nothing selected')
    }
  }, [value]);

  const selectOption = (option) => {
    let newValue;
    
      if (value === option.value) {
        newValue = null;
      } else {
        newValue = option.value;
      }
    setLabel(option.label);
      onChange(newValue);
    setOpen(false);
  };

  const reset = () => {
    onChange(null);
    setLabel(placeholder);
  }

  const optionIsSelected = (option) => {
    if (value === option.value) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root} ref={ref}>
      <label onClick={() => toggleOpen()}>
        {value !== null && (<span onClick={() => reset()} className={classes.clear}><Svg.xCircle/></span>)} {label} <span className='triangle'><Svg.Triangle /></span>
      </label>

      <div className={`${classes.dropdown} ${open ? 'open' : ''}`}>
        <ul>
          {options.map((option) => (
            <li
              key={option.value}
              value={option.value}
              onClick={() => selectOption(option)}
            >
              {option.label} {optionIsSelected(option) ? <Svg.Checkmark /> : ''}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FilterMenu;
