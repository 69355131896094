import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Svg from '../../svg/icons';
import useOutsideClick from '../../utils/useClickOutside';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as moment from 'moment';
import FormSelect from './FormSelect';
import Switch from '../../theme/Switch';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    marginRight: 24,
    userSelect: 'none',
    '& label': {
      display: 'flex',
      alignItems: 'center',
      padding: '6px 0',
      cursor: 'pointer',
      '& svg': {
        marginRight: 8,
      },
    },
  },
  disabled: {
    pointerEvents: 'none'
  },
  dropdown: {
    position: 'absolute',
    minWidth: 175,
    left: 0,
    top: '100%',
    transition: 'opacity 0.1s linear, transform 0.3s ease-out',
    opacity: 0,
    transform: 'translateY(-10px)',
    pointerEvents: 'none',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 15px 30px -10px rgba(158,158,158,0.3)',
    zIndex: 20,
    '&.open': {
      opacity: 1,
      transform: 'translateY(0px)',
      pointerEvents: 'all',
    },

    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      padding: '10px 16px',
      fontSize: 14,
      lineHeight: '16px',
      transition: 'all 0.2s linear',

      '& svg': {
        height: 16,
        float: 'right',
        '& path': {
          fill: '#252427',
          transition: 'all 0.2s linear',
        },
      },

      '&:hover': {
        backgroundColor: '#252427',
        color: 'white',
        '& svg path': {
          fill: 'white',
          transition: 'all 0.2s linear',
        },
      },
    },
  },
});
const DateWidget = ({ value, name, recurring, onChange, icon, disabled, ...props }) => {
  const classes = useStyles();
  const ref = useRef();
  const startDate = new Date();

  const [date, setDate] = useState(null);

  const [customDate, setCustomDate] = useState({
    m: false,
    t: false,
    w: false,
    th: false,
    f: false,
    s: false,
    su: false
  });


  const [recurringOptions, setRecurringOptions] = useState({
    isRecurring: false,
    recurringRepeat: 'daily',
    stopWhenComplete: false,
  })

  



  const { isRecurring, recurringRepeat, stopWhenComplete } = recurringOptions;

  const updateRecurring = (property, value) => {
    console.log(property, value)
    setRecurringOptions({...recurringOptions, [property]: value});
  }

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    console.log('new value detected', value, typeof value, value._seconds === undefined)
    
    if (value.length !== 0) {
      const dateEval = (value._seconds === undefined) ? new Date(value) : new Date(value._seconds * 1000);
      console.log(dateEval)
      setDate(dateEval);
      setLabel(moment(dateEval).format('MMM D'))
    } else {
      setDate(null);
      setLabel(props.children);
    }
    
    if (value === null) {
      // value = startDate;



      // setDate(null);
      // setLabel('Due Date')
    }
    else {
      // console.log(value);
      // setDate(value)
      // setLabel(moment(date).format('MMM D'))
    }
  }, [value])

  const repeatOptions = [{
    value: 'daily',
    label: 'Daily',
  }, {
    value: 'weekly',
    label: 'Weekly',
  }, {
    value: 'monthly',
    label: 'Monthly',
  }, {
    value: 'custom',
    label: 'Custom',
    }];
  
  
  const selectDay = (day) => {
    setCustomDate({...customDate, [day]: !customDate[day]});
  }

  const isDaySelected = (day) => {
    return customDate[day];
  }

   const getDate = (dueDate) => {
    if (dueDate === '') {
      return null;
    }
    return 
  }


  const [label, setLabel] = useState(props.children);

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const changeEvent = (e) => {
    console.log(e);
    onChange({ target: { name: name, value: e } })
    setDate(e);
    setLabel(moment(date).format('MMM D'));
    // setLabel(moment(new Date(e)).format('MMM D'))
  }

  return (
    <div className={`${classes.root} ${disabled ? classes.disabled : ''}`} ref={ref}>
      <label onClick={() => toggleOpen()}>{icon} {label}</label>

      <div className={`${classes.dropdown} ${open ? 'open' : ''}`}>
        <DatePicker
          name={name}
          selected={date}
          onSelect={changeEvent}
          inline
        />


        Repeat this task or note

        <Switch
                value={isRecurring}
                onChange={(e) => updateRecurring('isRecurring', e)}
                label='Enable recurring'
                name='isRecurring'
              />

        <FormSelect
              options={repeatOptions}
              value={recurringRepeat}
              name='recurringRepeat'
              onChange={(e) => updateRecurring('recurringRepeat', e)}
        ></FormSelect>

        Repeat Every

        {recurringRepeat === 'custom' && (<div className="week-options">
          <span className={`day ${isDaySelected('m')}`} onClick={() => {selectDay('m')}}>M</span>
          <span className={`day ${isDaySelected('t')}`} onClick={() => {selectDay('t')}}>T</span>
          <span className={`day ${isDaySelected('w')}`} onClick={() => {selectDay('w')}}>W</span>
          <span className={`day ${isDaySelected('th')}`} onClick={() => {selectDay('th')}}>T</span>
          <span className={`day ${isDaySelected('f')}`} onClick={() => {selectDay('f')}}>F</span>
          <span className={`day ${isDaySelected('s')}`} onClick={() => {selectDay('s')}}>S</span>
          <span className={`day ${isDaySelected('su')}`} onClick={() => {selectDay('su')}}>S</span>
        </div>)}
        
         <Switch
                value={stopWhenComplete}
                onChange={(e) => updateRecurring('stopWhenComplete', e)}
                label='Stop recurring when complete'
                name='stopWhenComplete'
              />
      </div>
    </div>
  );
};

export default DateWidget;
