import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Login from '../pages/login/Login';
import HowToLogin from '../pages/login/HowToLogin';
import LoginStyles from './Login.styles';
import { connect } from 'react-redux';
import { checkHotelSlug } from '../store/actions/hotelLoginActions';

const LoginView = ({
  match,
  hotelLogin: { name, slug, error, loading },
  history,
  authenticated,
  checkHotelSlug,
}) => {
  const styles = LoginStyles();

  useEffect(() => {
    if (authenticated) {
      history.push(`/${match.params.hotelSlug}/`);
    } else {
      checkHotelSlug(match.params.hotelSlug);
    }
    //eslint-disable-next-line
  }, [authenticated]);

  useEffect(() => {
    if (error) {
      // alert(error.msg);
    }
  }, [error]);

  return (
    <div>
      {loading && (
      <div style={{background: 'white', position: 'fixed', width: '100vw', height: '100vh'}}>
      Loading hotel...
      {/* <Loader /> */}

      </div>
    )}
     {error && (
      <div style={{background: 'white', position: 'fixed', width: '100vw', height: '100vh'}}>
      Hotel error... {error}
      {/* <Loader /> */}

      </div>
    )}
    {!loading && !error && (
      <div>
    <div className={styles.root}>
      {slug}
      <Route exact path={`/login`} component={HowToLogin} />
      <Route path={`/${slug}/login`} component={Login} />
    </div>
    </div>
    )}

    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  hotel: state.hotel,
  hotelLogin: state.hotelLogin,
});
const mapActionsToProps = {
  // getHotelBySlug,
  checkHotelSlug,
};

export default connect(mapStateToProps, mapActionsToProps)(LoginView);
